import React from 'react';
import * as S from './styles';

export interface CardsTutorialsProps {
  id_tutorial: string;
  tutorial_title: string;
  url_thumbnail: string;
  url_video: string;
  tutorialSelected: (id: string) => void;
}

export function VideoCard({
  id_tutorial,
  tutorial_title,
  url_thumbnail,
  url_video,
  tutorialSelected,
}: CardsTutorialsProps) {
  return (
    <S.CardWrapper>
      <S.Image src={url_thumbnail} alt='banner' />
      <S.Title>{tutorial_title}</S.Title>
      <S.ButtonPlay
        title='Clique aqui para assistir a este tutorial'
        onClick={() => tutorialSelected(id_tutorial)}
      >
        <S.PlayIcon />
      </S.ButtonPlay>
    </S.CardWrapper>
  );
}
