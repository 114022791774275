import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { CellProps } from 'react-table';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/toast';
import {
  Loading,
  SaveButton,
  CustomizedTable,
  Table,
  Template,
} from 'components';
import { SectorStyleProps } from 'components/CustomizedTable/interface';
import { apiAngel } from 'services/data';
import { FaEye } from 'react-icons/fa';
import * as S from './styles';

interface ResponseAssunto {
  id: number;
  fase: string;
}
interface ResponseAtendimento {
  id: number;
  data: Date;
  assunto: string;
  descricao: string;
  atribuido_a: string;
  chamado: string;
}

const AnjoAtendimento = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [naoRealizado, setNaoRealizado] = useState<number>(0);
  const [andamento, setAndamento] = useState<number>(0);
  const [realizado, setRealizado] = useState<number>(0);
  const [assunto, setAssunto] = useState<ResponseAssunto[]>([]);
  const [atendimentos, setAtendimentos] = useState<ResponseAtendimento[]>([]);
  const history = useHistory();
  const { addToast } = useToast();
  const { register, handleSubmit } = useForm();

  async function fetchData() {
    try {
      // const response = await api.get(`/anjo/atendimento`);
      await apiAngel.index().then((response) => {
        setAssunto(response.data.assuntos);
        setAtendimentos(
          response.data.atendimentos.map((item: ResponseAtendimento) => ({
            id: item.id,
            data: format(parseISO(item.data.toString()), 'dd/MM/yyyy', {
              locale: ptBR,
            }),
            assunto: item.assunto,
            descricao: item.descricao,
            atribuido_a: item.atribuido_a,
            chamado: item.chamado === 'sim' ? 'Fechado' : 'Aberto',
          }))
        );
        setRealizado(
          response.data.atendimentos.filter((item: ResponseAtendimento) => {
            return item.chamado === 'sim';
          }).length
        );
        setNaoRealizado(
          response.data.atendimentos.filter((item: ResponseAtendimento) => {
            return (
              item.chamado === 'nao' &&
              parseISO(item.data.toString()) < new Date()
            );
          }).length
        );
        setAndamento(
          response.data.atendimentos.filter((item: ResponseAtendimento) => {
            return (
              (item.chamado === 'nao' &&
                parseISO(item.data.toString()) > new Date()) ||
              item.chamado === ''
            );
          }).length
        );
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar os dados de atendimento',
        description: (err as Error).message,
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisualizar = useCallback(
    ({ id }) => {
      history.push(`/anjo/atendimentos/${id}`);
    },
    [history]
  );

  const onSubmit = useCallback(
    async (body) => {
      try {
        await apiAngel.store({ ...body }).then(() => {
          addToast({
            type: 'success',
            title: 'Atendimento',
            description:
              'Atendimento cadastrado com sucesso! Em breve entraremos em contato!',
          });
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao realizar o Log',
          description: (err as Error).message,
        });
      }
      await fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast]
  );

  const columnAtendimentos = useMemo(
    () => [
      {
        Header: 'Atendimento',
        columns: [
          {
            Header: 'Data',
            accessor: 'data',
          },
          {
            Header: 'Assunto',
            accessor: 'assunto',
          },
          {
            Header: 'Descrição',
            accessor: 'descricao',
          },
          {
            Header: 'Atribuido a',
            accessor: 'atribuido_a',
          },
          {
            Header: 'Chamado',
            accessor: 'chamado',
          },
        ],
      },
      {
        Header: 'Operações',
        columns: [
          {
            Header: 'Visualizar',
            Cell: ({ cell }: CellProps<{}>) => (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => handleVisualizar({ ...cell.row.original })}
              >
                <FaEye size={24} color='white' />
              </button>
            ),
          },
        ],
      },
    ],
    [handleVisualizar]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.TitleContainer>
            <S.Title>Faça aqui sua solicitação</S.Title>
          </S.TitleContainer>
          <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
            <S.InputContainer>
              <label htmlFor='assunto'>
                <h1>Assunto:</h1>
              </label>
              <select name='assunto' required ref={register}>
                <option></option>
                {assunto.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.fase}
                  </option>
                ))}
              </select>
            </S.InputContainer>
            <S.InputContainer>
              <label htmlFor='atendente'>
                <h1>Atendente:</h1>
              </label>
              <select name='atendente' required ref={register}>
                <option></option>
                <option value='anjo'>Analista</option>
                <option value='outro'>Outro</option>
              </select>
            </S.InputContainer>
            <S.InputContainer>
              <label htmlFor='solicitante'>
                <h1>Seu nome:</h1>
              </label>
              <input type='text' name='solicitante' required ref={register} />
            </S.InputContainer>
            <S.InputContainer>
              <label htmlFor='descricao'>
                <h1>Descrição:</h1>
              </label>
              <textarea name='descricao' required ref={register}></textarea>
            </S.InputContainer>
            <S.ButtonContainer>
              <SaveButton bgColor='red' title='red' iconBg='var(--white)' />
            </S.ButtonContainer>
          </S.FormContainer>
          <S.SummaryContainer>
            <S.TitleContainer>
              <S.Title>Acompanhe sua solicitação</S.Title>
            </S.TitleContainer>
            <S.SummaryCard bgColor='var(--red-500)'>
              <S.SummaryTitle>Não realizados</S.SummaryTitle>
              <S.SummaryTitle>{naoRealizado && naoRealizado}</S.SummaryTitle>
            </S.SummaryCard>
            <S.SummaryCard bgColor='var(--red-500)'>
              <S.SummaryTitle>Em andamento</S.SummaryTitle>
              <S.SummaryTitle>{andamento && andamento}</S.SummaryTitle>
            </S.SummaryCard>
            <S.SummaryCard bgColor='var(--red-500)'>
              <S.SummaryTitle>Realizados</S.SummaryTitle>
              <S.SummaryTitle>{realizado && realizado}</S.SummaryTitle>
            </S.SummaryCard>
          </S.SummaryContainer>
          <CustomizedTable sector={SectorStyleProps['RED']}>
            <Table columns={columnAtendimentos} data={atendimentos} />
          </CustomizedTable>
        </Template>
      )}
    </>
  );
};

export default AnjoAtendimento;
