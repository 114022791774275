import api from '../../api';
import { IBillet } from 'interfaces/Billet/Billet.interface';

class BilletData {
  index() {
    return api.get(`administrativo/financeiro`);
  }
  store(body: IBillet) {
    return api.post(`administrativo/financeiro/log`, body);
  }
}

export default new BilletData();
