import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';

const AdministrativoMenu = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('administrativo/arquivos')}
            >
              ARQUIVOS
            </ButtonGigi>

            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('administrativo/financeiro')}
            >
              FINANCEIRO
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('administrativo/notafiscal')}
            >
              NOTAS FISCAIS
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('administrativo/parceirosComerciais')}
            >
              PARCEIROS HOMOLOGADOS
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default AdministrativoMenu;
