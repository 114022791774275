import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { apiFile } from 'services/data';
import { useToast } from 'hooks/toast';
import { useUrl } from 'hooks/url';
import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from 'jwt-decode';

import { Loading, Card, Template } from 'components';

interface iToken {
  licenciado_id: number;
}

type FilesData = {
  id: number;
  arquivo: string;
  hora: string;
  rede_pessoa: {
    nome: string;
  };
};

const AdministrativoArquivoSuaFarmaciaCredenciamento = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [licensee, setLicensee] = useState(0);
  const [files, setFiles] = useState<FilesData[]>([]);
  const { keycloak } = useKeycloak();
  const { addToast } = useToast();
  const { urlCrm } = useUrl();

  useEffect(() => {
    async function fetchData() {
      try {
        const token: iToken = jwt_decode(keycloak.token || '');
        setLicensee(token.licenciado_id || 0);
        await apiFile.especifico(7).then((response) => setFiles(response.data));
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar os arquivos',
          description: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [addToast, keycloak]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='archive-content'>
            {files.length > 0 ? (
              files.map((item) => {
                const extensao = item.arquivo.split('.').pop() || 'png';
                return (
                  <Card
                    key={item.id}
                    image={`${urlCrm}/licenciado/${licensee}/${item.id}.${extensao}`}
                    extensao={extensao}
                    text={item.arquivo
                      .split('_')
                      .join(' ')
                      .split('.')
                      .slice(0, -1)
                      .join('')}
                    legend={`${item.rede_pessoa.nome} - ${format(
                      parseISO(item.hora.toString()),
                      'dd/MM/yyyy HH:mm:ss',
                      {
                        locale: ptBR,
                      }
                    )}`}
                    path={`${urlCrm}/licenciado/${licensee}/${item.id}.${extensao}`}
                  />
                );
              })
            ) : (
              <span style={{ margin: '0 auto', color: 'var(--grey)' }}>
                Desculpe, nenhum arquivo encontrado
              </span>
            )}
          </div>
        </Template>
      )}
    </>
  );
};

export default AdministrativoArquivoSuaFarmaciaCredenciamento;
