import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';

export const ButtonClose = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, var(--red-500), var(--red-400));
  z-index: 50;
  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(FaTimesCircle)`
  width: 1.75rem;
  height: 1.75rem;
  fill: #fff;
`;
