import styled, { css } from "styled-components";

interface ButtonProps {
  size?: "menor" | "default";
  bgColor?: "red" | "black" | "disabled";
}

const sizeVariations = {
  menor: css`
    height: 120px;
    max-width: 320px;
    font-size: 1.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  `,

  default: css`
    height: 160px;
    width: 320px;
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    @media (max-width: 1016px) {
      div {
        font-size: 28px;
      }
      width: 280px;
    }
    @media (max-width: 992px) {
      width: 240px;
      div {
        font-size: 24px;
      }
    }
    @media (max-width: 850px) {
      width: 40vw;
      height: 20vh;
      div {
        font-size: 0.7em;
        img {
          width: 20vw;
          height: 13vh;
        }
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      div {
        img {
          width: 100%;
        }
      }
    }
  `,
};

const colorVariations = {
  red: css`
    background: var(--red-500);
    color: var(--white);
    transition: all 250ms ease-in-out;

    &:hover {
      filter: brightness(0.85);
    }
  `,
  black: css`
    background: black;
    color: var(--white);
    border: 4px solid green;
    transition: all 250ms ease-in-out;

    &:hover {
      filter: brightness(0.85);
    }
  `,
  disabled: css`
    background-image: linear-gradient(to right, #b5b5b5, #757575);
    color: var(--white);
    cursor: not-allowed !important;
    transition: all 250ms;

    &:hover {
      filter: brightness(0.85);
    }
  `,
};

const ButtonGigi = styled.button<ButtonProps>`
  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.45rem;
  border: 0;
  border-radius: 1rem;
  cursor: pointer;

  ${(props) => sizeVariations[props.size || "default"]}
  ${(props) => colorVariations[props.bgColor || "red"]}

  &:focus {
    outline: none;
  }

  div {
    border: 1px solid var(--white);
    border-radius: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default ButtonGigi;
