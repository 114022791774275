import React, { useState, useEffect, useMemo } from 'react';
import { Loading, CustomizedTable, Table, Template } from 'components';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { SectorStyleProps } from 'components/CustomizedTable/interface';
import { IParceirosComerciais } from 'interfaces/ParceirosComerciais/ParceirosComerciais';
import { apiParceirosComerciais } from 'services/data';
import { CellProps } from 'react-table';
import { FaEye } from 'react-icons/fa';
import * as S from './styles';

const ParceirosComerciais = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IParceirosComerciais[]>([]);
  const { addToast } = useToast();
  const history = useHistory();

  const fetchData = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const responseData = await apiParceirosComerciais
        .index()
        .then((response) => {
          setData(
            response.data.map((item: IParceirosComerciais) => ({
              id: item.id,
              empresa: item.empresa,
              responsavel: item.responsavel,
              contato: item.contato,
              email: item.email,
              ramo: item.ramo,
              condicao: item.condicao,
            }))
          );
        });
    } catch (err) {
      addToast({
        title: 'Falhou!',
        description: 'Falha ao carregar informações de parceiros comerciais',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Parceiros',
        columns: [
          {
            Header: 'Empresa',
            accessor: 'empresa',
          },
          {
            Header: 'Responsavel',
            accessor: 'responsavel',
          },
          {
            Header: 'Telefone',
            accessor: 'contato',
          },
          {
            Header: 'E-mail',
            accessor: 'email',
          },
          {
            Header: 'Ramo',
            accessor: 'ramo',
          },
        ],
      },
      {
        Header: 'Ações',
        columns: [
          {
            Header: 'Ver mais',
            Cell: ({ cell }: CellProps<IParceirosComerciais>) => {
              return (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() =>
                    history.push(`parceirosComerciais/${cell.row.original.id}`)
                  }
                >
                  <FaEye size={24} color='white' />
                </button>
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.Description>
            <h4>
              Aqui você encontra serviços e produtos em nossos parceiros para
              lhe auxiliar no dia a dia de sua farmácia.
            </h4>
          </S.Description>
          <CustomizedTable sector={SectorStyleProps['RED']}>
            <Table columns={columns} data={data} />
          </CustomizedTable>
        </Template>
      )}
    </>
  );
};

export default ParceirosComerciais;
