import styled, { css } from 'styled-components';
import { SectorStyleProps } from './interface';

interface TableProps {
  sector: SectorStyleProps;
}

const searchBgColorVariations = {
  red: css`
    background-color: var(--red-400);
    border: 2px solid var(--red-500);
  `,
};

const tableColorVariations = {
  red: css`
    border: 3px solid var(--red-500);

    td,
    th {
      border: 3px solid var(--red-500);
      padding: 0.5rem;
      color: var(--white);
    }

    tr:nth-child(even) {
      background-color: var(--red-400);
    }

    tr:hover {
      filter: brightness(0.7);
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: var(--red-500);
      color: var(--white);
    }
  `,
};

export const Container = styled.div<TableProps>`
  div.search {
    margin: 0 0 8px 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--white);
    input {
      ${({ sector }) => searchBgColorVariations[sector]}
      border-radius: 5px;
      font-size: 13px;
      color: var(--white);
    }
  }

  table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;

    ${({ sector }) => tableColorVariations[sector]}

    th {
      padding: 1rem;

      div {
        display: grid;
        grid-template-columns: auto 5px;
        align-self: center;
      }
    }

    button {
      width: 100%;
      border: 0;
      background: transparent;
      cursor: pointer;
      svg {
        width: 100%;
      }
    }
  }

  div.paginate {
    margin: 10px 0 0 0;

    button {
      ${({ sector }) => searchBgColorVariations[sector]}
      padding: 0.75em;
      font-size: 1em;
      color: var(--white);
      cursor: pointer;
      border-radius: 0.5rem;

      &:disabled {
        color: var(--grey);
        cursor: auto;
      }
    }

    input,
    span,
    select {
      font-size: 1em;
      color: var(--white);
    }

    input {
      width: 4rem;
      height: max-content;
      text-align: center;
      ${({ sector }) => searchBgColorVariations[sector]}
      border-radius: 0.5rem;
      padding: 0.5rem;
      color: var(--white);
    }
  }
`;
