import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Loading, VideoCard, ModalTutorials, Template } from 'components';
import { apiTutorials, apiDepartments } from 'services/data';
import { ITutorialsDTO } from 'interfaces/Tutorials';
import { IDepartmentDTO } from 'interfaces/Departments';
import * as S from './styles';

export function Treinamentos() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tutorials, setTutorials] = useState<ITutorialsDTO[]>([]);
  const [departments, setDepartments] = useState<IDepartmentDTO[]>([]);
  const [urlTutorialSelected, setUrlTutorialSelected] = useState('');
  const [idDepartmentFilter, setIdDepartmentFilter] = useState('');

  async function fetchData() {
    const tutorialsDataFetch = await apiTutorials.getAll();
    const departmentsDataFetch = await apiDepartments.getAll();

    setTutorials(tutorialsDataFetch.data);
    setDepartments(departmentsDataFetch.data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const tutorialsFiltering = useMemo(() => {
    if (idDepartmentFilter === '') {
      return tutorials;
    }

    let tutorialsFiltered: ITutorialsDTO[] = tutorials.filter(
      (tt) => tt.id_department === idDepartmentFilter
    );

    return tutorialsFiltered;
  }, [idDepartmentFilter, tutorials]);

  async function handleCreatingViewLogTheTutorial(
    id_tutorial: string,
    url: string
  ) {
    await apiTutorials.createLogger(id_tutorial);
    setUrlTutorialSelected(url);
    setIsOpenModal(true);
  }

  function onRequestClose() {
    setIsOpenModal(false);
  }

  function handleFilteringTheTutorials(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    setIdDepartmentFilter(value);
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.FilterList>
            <label
              htmlFor='filter_department'
              style={{ color: 'var(--white)' }}
            >
              Filtro por departamento:
            </label>
            <form>
              <input
                type='radio'
                name='all'
                id='all'
                key='all'
                value=''
                checked={idDepartmentFilter === ''}
                onChange={handleFilteringTheTutorials}
                style={{ marginLeft: '0.5rem' }}
              />
              <label
                htmlFor='Todos'
                style={{ marginLeft: '0.25rem', color: 'var(--white)' }}
              >
                Todos
              </label>
              {departments.map((dp) => (
                <>
                  <input
                    type='radio'
                    name={dp.department}
                    id={dp.id}
                    key={dp.id}
                    value={dp.id}
                    checked={idDepartmentFilter === dp.id}
                    onChange={handleFilteringTheTutorials}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <label
                    htmlFor={dp.department}
                    style={{ marginLeft: '0.25rem', color: 'var(--white)' }}
                  >
                    {dp.department}
                  </label>
                </>
              ))}
            </form>
          </S.FilterList>

          <S.ContentContainer>
            {tutorialsFiltering.map((tt) => (
              <VideoCard
                id_tutorial={tt.id}
                tutorial_title={tt.tutorial_title}
                url_thumbnail={`https://auth.redeinovadrogarias.com.br:8012/uploads/tutorials/${tt.url_thumbnail}`}
                url_video={tt.url_video}
                tutorialSelected={() =>
                  handleCreatingViewLogTheTutorial(tt.id, tt.url_video)
                }
              />
            ))}

            <ModalTutorials
              isOpen={isOpenModal}
              handleRequestClose={onRequestClose}
              video_url={urlTutorialSelected}
            />
          </S.ContentContainer>
        </Template>
      )}
    </>
  );
}
