import api from '../../api';

class ParceirosComerciais {
  index() {
    return api.get('parceirosComerciais');
  }

  showOne(id: string) {
    return api.get(`parceirosComerciais/${id}`);
  }
}

export default new ParceirosComerciais();
