import React from 'react';
import { IoIosSave } from 'react-icons/io';
import * as S from './styles';

interface Props {
  bgColor: 'red';
  title: 'red';
  iconBg: string;
}

const SaveButton: React.FC<Props> = ({ bgColor, title, iconBg }) => {
  return (
    <S.Container bgColor={bgColor}>
      <IoIosSave color={iconBg} size={30} />
      <S.Title title={title}>Salvar</S.Title>
    </S.Container>
  );
};

export default SaveButton;
