import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { format, parseISO } from "date-fns";
import * as S from "./styles";
import fundo from "assets/images/locucao/fundo_edicao_rid.png";
import setas from "assets/images/locucao/setas.png";
import voltar from "assets/images/locucao/voltar.svg";
import proximo from "assets/images/locucao/proximo.svg";
import edicao from "assets/images/locucao/edicao.svg";
import icone_audio from "assets/images/locucao/icone_audio_rid.svg";
import botao_excluir from "assets/images/locucao/botao_excluir_rid.svg";
import rid_radio from "assets/images/locucao/rid_radio.svg";
import { useToast } from "hooks/toast";
import { apiLocucao } from "services/data";
import { useUrl } from "hooks/url";

interface Response {
  id?: number;
  userId?: number;
  repeat: number;
  start: Date;
  end: Date;
  url: string;
}

const Locucao: React.FC = () => {
  const [data, setData] = useState<Response[]>([]);
  const history = useHistory();
  const { addToast } = useToast();
  const { urlAgencia } = useUrl();

  const fetchData = useCallback(async () => {
    try {
      apiLocucao.index().then((response) => {
        setData(response.data);
      });
    } catch (err) {
      addToast({
        type: "error",
        title: "Error",
        description: "Erro ao cadastrar a Locução",
      });
    }
  }, [addToast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRemove = useCallback(
    async (id) => {
      try {
        apiLocucao.destroy(id).then(async () => {
          await fetchData();
          addToast({
            type: "success",
            title: "Locução",
            description: "Locução apagada com sucesso!",
          });
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Error",
          description: "Erro ao apagar a Locução",
        });
      }
    },
    [addToast, fetchData]
  );

  return (
    <S.Container>
      <S.Header>
        <img src={setas} alt="Setas Locução" className="setas" />
        <div>
          <button>
            <img
              src={voltar}
              alt="Voltar Locução"
              onClick={() => history.push("/radio")}
              className="voltar"
            />
            VOLTAR
          </button>
        </div>
      </S.Header>
      <S.Body>
        <img src={edicao} alt="Edicação de Áudio" className="aovivo" />
      </S.Body>
      <S.BarRidRadio>
        <img src={rid_radio} alt="Logo Rid Áudio" />
      </S.BarRidRadio>
      <S.BarAudios>
        {data.length > 0 &&
          data.map((item, key) => (
            <div key={key}>
              <div>
                <img src={icone_audio} alt="Icone Áudio" />
              </div>
              <div className="audio">
                <ReactAudioPlayer
                  src={`${urlAgencia}/uploads/locucao/${item.userId}/online/${item.url}`}
                  controls
                  preload="auto"
                  style={{ width: "100%" }}
                />
              </div>
              <div>Repetir a cada {item.repeat} minutos</div>
              <div>
                De{" "}
                {format(parseISO(item.start.toString()), "dd/MM/yyyy hh:mm:ss")}
                <br />a{" "}
                {format(parseISO(item.end.toString()), "dd/MM/yyyy hh:mm:ss")}
              </div>
              <div className="remover">
                <img
                  src={botao_excluir}
                  alt="Botão excluir Áudio"
                  onClick={() => handleRemove(item.id)}
                />
              </div>
            </div>
          ))}
      </S.BarAudios>
      <S.Header>
        <div>
          <button>
            <img
              src={proximo}
              alt="Próximo Locução"
              onClick={() => history.push("/locucao")}
              className="voltar"
            />
            ADICIONAR <br />
            LOCUÇÃO
          </button>
        </div>
      </S.Header>
      <img src={fundo} alt="Fundo locução" />
    </S.Container>
  );
};

export default Locucao;
