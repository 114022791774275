import styled, { css } from 'styled-components';

interface ButtonProps {
  size?: 'default';
  bgColor?: 'primary' | 'default' | 'secondary' | 'success' | 'gigi';
}

const sizeVariations = {
  default: css`
    height: 35px;
    width: 150px;
    font-size: 16px;
  `,
};

const colorVariations = {
  primary: css`
    background: var(--primary);
    color: var(--white);
    &:hover {
      background: var(--primary-hover);
    }
  `,
  secondary: css`
    background: var(--secondary);
    color: var(--white);
    &:hover {
      background: var(--secondary-hover);
    }
  `,

  default: css`
    background: var(--main);
    color: var(--white);
    &:hover {
      background: var(--main-hover);
    }
  `,

  gigi: css`
    background: var(--gigi);
    color: var(--white);
    &:hover {
      background: var(--gigi-hover);
    }
  `,

  success: css`
    background: var(--success);
    color: var(--white);
    &:hover {
      background: var(--success-hover);
    }
  `,
};

const Button = styled.button<ButtonProps>`
  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 6px;
  font-weight: 500;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  ${(props) => sizeVariations[props.size || 'default']}
  ${(props) => colorVariations[props.bgColor || 'default']}
  &:focus {
    outline: none;
  }
`;
export default Button;
