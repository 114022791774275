import { apiAgencia } from 'services/api';

class TutorialsApi {
  getAll() {
    return apiAgencia.get('/tutorials');
  }

  getById(id: string) {
    return apiAgencia.get(`/tutorials/id_tutorial/${id}`);
  }

  create(data: FormData) {
    return apiAgencia.post('/tutorials', data);
  }

  update(id: string, data: FormData) {
    return apiAgencia.put(`/tutorials/upt/${id}`, data);
  }

  delete(id: string) {
    return apiAgencia.delete(`/tutorials/${id}`);
  }

  createLogger(id: string) {
    return apiAgencia.post(`/tutorialsLogger/${id}`);
  }
}

export default new TutorialsApi();
