import styled from 'styled-components';
import { VscFilePdf } from 'react-icons/vsc';

export const ContainerContent = styled.div`
  width: 100%;
  height: 78vh;
  margin-top: 1rem;
  padding: 1rem 1rem;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.35);
    width: 7px;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
      to bottom,
      var(--red-500),
      var(--red-700)
    );
    border-radius: 5px;
  }

  .alert {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
    background: var(--gray-700);
    border: 2px solid var(--primary-gradient-rid);
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    p {
      color: var(--primary-gradient-rid);
      font-family: sans-serif;
      font-weight: 500;
      text-align: justify;
    }
  }
`;

export const ContainerNfs = styled.div`
  width: 100%;
  height: 75px;
  background: var(--gray-700);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 1rem;
  }

  .description {
    display: flex;
    flex-direction: column;

    span {
      font-family: Sans-serif;
      font-weight: 500;
      color: var(--grey);
      line-height: 1.5rem;
    }

    b {
      color: var(--grey);
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;

    span {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-family: Sans-serif;
      font-weight: 500;
      color: var(--grey);
    }

    .button__archive {
      border: none;
      border-radius: 0.5rem;
      background: linear-gradient(45deg, var(--red-500), var(--red-700));
      color: var(--grey);
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 350ms;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media screen and (max-width: 420px) {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .description {
      b {
        text-align: center;
      }
    }

    .buttons {
      flex-direction: column;

      span {
        text-align: center;
      }
    }
  }
`;

export const iconPdf = styled(VscFilePdf)`
  width: 32px;
  height: 32px;
  fill: var(--grey);
`;
