import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';

const AdministrativoArquivo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('/administrativo/arquivos/geral')}
            >
              GERAL
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() =>
                history.push('/administrativo/arquivos/parasuafarmacia')
              }
            >
              PARA SUA FARMÁCIA
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default AdministrativoArquivo;
