import api from "../../api";

interface IReservationProps {
  name: string;
  code: number;
  email: string;
  state: string;
  ip: string;
  newspaperType: string;
  newspaperQuant: number;
  newspaperTimeRservation: number;
}

class JornalDeOfertaData {
  index() {
    return api.get("/comercial/jornalDeOfertas/");
  }

  store(body: IReservationProps) {
    return api.post("/comercial/jornalDeOfertas/new", body);
  }

  delete(id: string) {
    return api.delete(`/comercial/jornalDeOfertas/delete/${id}`);
  }
}

export default new JornalDeOfertaData();
