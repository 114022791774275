import React, { useCallback } from 'react';
import * as S from './styles';
import { FaWhatsapp } from 'react-icons/fa';

interface Props {
  imagePath: string;
}

const Header: React.FC<Props> = ({ imagePath }) => {
  const handleWhatsappApi = useCallback(() => {
    const whatsappApiUrl =
      'https://api.whatsapp.com/send?phone=+55%20(33)%203271-3016';
    window.open(whatsappApiUrl, '_blank');
  }, []);

  return (
    <S.Container>
      <S.LogoImage src={`${imagePath}`} />
      <S.WhatsappContainer>
        <S.WhatsappInfo onClick={handleWhatsappApi}>
          <div>
            <FaWhatsapp size={32} color='white' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <S.WhatsappMessage>Fale conosco via Whatsapp:</S.WhatsappMessage>
            <S.WhatsappNumber>+55 (33) 3271-3016</S.WhatsappNumber>
          </div>
        </S.WhatsappInfo>
      </S.WhatsappContainer>
    </S.Container>
  );
};

export default Header;
