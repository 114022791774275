import { useEffect, useMemo, useState } from 'react';
import { Loading, CustomizedTable, Table, Template } from 'components';
import { SectorStyleProps } from 'components/CustomizedTable/interface';
import { CellProps } from 'react-table';
import { useToast } from 'hooks/toast';
import { apiJornalDeOfertas } from 'services/data';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as S from './styles';

interface ICRMProblemaProps {
  id: number;
  acontece: Date;
  crm_fase: { fase: string };
  problema: string;
  rede_pessoa_crm_problema_id_pessoaTorede_pessoa: { nome: string };
  resolvido: string;
}

export function ComercialJornalDeOfertasMinhasReservas() {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [atendimentos, setAtendimentos] = useState<ICRMProblemaProps[]>([]);

  async function fecthData() {
    try {
      const atendimentosData = await apiJornalDeOfertas.index();

      setAtendimentos(
        atendimentosData.data.map((item: ICRMProblemaProps) => ({
          id: item.id,
          data: format(parseISO(item.acontece.toString()), 'dd/MM/yyyy', {
            locale: ptBR,
          }),
          fase: item.crm_fase.fase,
          problema: item.problema,
          pessoa: item.rede_pessoa_crm_problema_id_pessoaTorede_pessoa.nome,
          resolvido: item.resolvido,
        }))
      );
      setIsLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar os dados de atendimento',
        description: '',
      });
    }
  }

  useEffect(() => {
    fecthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleDeleteAtendimento(id: string) {
    const date = new Date();
    const day = date.getDate();

    if (day <= 15) {
      try {
        const deleted = await apiJornalDeOfertas.delete(id);

        if (deleted) {
          addToast({
            type: 'success',
            title: 'Eba! :)',
            description: 'Êxito ao apagar os dados da reserva',
          });

          fecthData();
        } else {
          addToast({
            type: 'error',
            title: 'Ops! :(',
            description: 'Falha ao excluir os dados da reserva',
          });
        }
      } catch (error) {
        addToast({
          type: 'info',
          title: 'Ops! :(',
          description: `${error}`,
        });
      }
    } else {
      addToast({
        type: 'info',
        title: 'Falha :(',
        description: 'Você só pode cancelar uma reserva antes do dia 15 do mês',
      });
    }
  }

  const columnAtendimentos = useMemo(
    () => [
      {
        Header: 'Atendimento',
        columns: [
          {
            Header: 'Data',
            accessor: `data`,
          },
          {
            Header: 'Assunto',
            accessor: 'fase',
          },
          {
            Header: 'Descrição',
            accessor: 'problema',
          },
          {
            Header: 'Atribuido a',
            accessor: 'pessoa',
          },
          {
            Header: 'Atendido',
            accessor: 'resolvido',
          },
        ],
      },
      {
        Header: 'Operações',
        columns: [
          {
            Header: 'Apagar',
            Cell: ({ cell }: CellProps<ICRMProblemaProps>) => (
              <button
                type='button'
                className='btn'
                onClick={() =>
                  handleDeleteAtendimento(`${cell.row.original.id}`)
                }
                disabled={cell.row.original.resolvido === 'sim' ? true : false}
              >
                <S.IconTrash />
              </button>
            ),
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [atendimentos]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.TitleContainer>
            <S.Title>Minhas Reservas de Jornal de Ofertas</S.Title>
          </S.TitleContainer>

          <CustomizedTable sector={SectorStyleProps['RED']}>
            <Table columns={columnAtendimentos} data={atendimentos} />
          </CustomizedTable>
        </Template>
      )}
    </>
  );
}
