import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { apiFinancingStatus } from 'services/data';
import logoRidRadio from 'assets/images/icons/logo-ridradio.svg';
import logoRidTv from 'assets/images/icons/logo-ridtv.svg';
import logoIdRouse from 'assets/images/icons/logo-idhouse.svg';
import logoKitJornal from 'assets/images/icons/logo-kitjornal.svg';
import logoJornalFacil from 'assets/images/icons/Logo_jornalFacil.svg';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';

const Marketing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [financingStatus, setFinancingStatus] = useState(false);
  const history = useHistory();

  async function fetchFinancingStatus() {
    const requestFinancingStatus = await apiFinancingStatus.getStatus();
    setFinancingStatus(requestFinancingStatus.data.debito);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchFinancingStatus();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi
              bgColor={financingStatus ? 'disabled' : 'red'}
              disabled={financingStatus ? true : false}
              type='button'
              onClick={() =>
                window.open(
                  'https://licenciado.redeinovadrogarias.com.br/radio',
                  '_blank'
                )
              }
              title='Clique aqui para acessar a RID Rádio'
            >
              <img src={logoRidRadio} alt='Logo Rádio Rede Inova' />
            </ButtonGigi>
            <ButtonGigi
              bgColor={financingStatus ? 'disabled' : 'red'}
              disabled={financingStatus ? true : false}
              type='button'
              onClick={() =>
                window.open(
                  'https://licenciado.redeinovadrogarias.com.br/tv',
                  '_blank'
                )
              }
              title='Clique aqui para acessar a RID TV'
            >
              <img src={logoRidTv} alt='Logo TV Rede Inova' />
            </ButtonGigi>
            <ButtonGigi
              bgColor={financingStatus ? 'disabled' : 'red'}
              disabled={financingStatus ? true : false}
              type='button'
              onClick={() =>
                window.open(
                  'https://agencia.redeinovadrogarias.com.br',
                  '_blank'
                )
              }
              title='Clique aqui para acessar a agência de Marketing'
            >
              <img src={logoIdRouse} alt='Logo ID House' />
            </ButtonGigi>
            <ButtonGigi
              type='button'
              bgColor={financingStatus ? 'disabled' : 'red'}
              disabled={financingStatus ? true : false}
              onClick={() => history.push('/marketing/kitjornalofertas')}
              title='Clique aqui para acessar os itens do Kit Varejo'
            >
              <img src={logoKitJornal} alt='Logo Kit Jornal' />
            </ButtonGigi>
            <ButtonGigi
              type='button'
              bgColor={financingStatus ? 'disabled' : 'red'}
              disabled={financingStatus ? true : false}
              onClick={() =>
                window.open(
                  'https://form.jotform.com/220105721937046',
                  '_blank'
                )
              }
              title='Clique aqui para acessar o formulário para realizar o pedido do Jornal Fácil'
            >
              <img
                src={logoJornalFacil}
                alt='Logo Jornal Fácil'
                style={{
                  width: '100%',
                  height: '80px',
                  objectFit: 'scale-down',
                }}
              />
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default Marketing;
