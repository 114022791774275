import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 4rem 1rem;
  overflow-x: hidden;
`;

export const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
`;
