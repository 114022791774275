import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Gotham Black';
    font-weight: 500;
    font-style: normal;
    src: local('Gotham-Black'), url("../assets/fonts/Gotham-Black.ttf") format("truetype");
  }

  :root {
    --red-700: #801313;
    --red-500: #ff0000;
    --red-400: #e53e3e;
    --red-50: #FF9494;
    --gray-800: #18181b;
    --gray-700: #27272a;
    --green-400: #00bc59; 
    --main-bg-color: #0D0C0C;
    --main: #DC0000;
    --main-hover: #780000;
    --download: #FF6600;
    --download-hover: #BA2B00;
    --white: #ffffff;
    --black: #000000;
    --grey: #f0f0f0;
    --primary: #007bff;
    --primary-hover: #0069d9;
    --danger: #dc3545;
    --danger-hover: #c82333;
    --secondary: #6c757d;
    --secondary-hover: #5a6268;
    --toast-default-bg: #ebf8ff;
    --toast-default-cl: #3172b7
    --bg-error: #f5c6cb;
    --border-error: #a43d3d;
    --success: #00BC59;
    --success-hover: #00823D;
    --primary-gradient-rid: #ffb245;
    --secondary-gradient-rid: #e73439;
    --tercenary-gradient-rid: #b31b62;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  };

  html, body, #root {
    height: 100%;
    width: 100%;
    background: var(--white);
  }

  *, button, input {
    font-family: 'Gotham Black',Arial,sans-serif;
  }

  .button-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .archive-content {
    width: 100%;
    height: 100%;
    gap: 0.75rem;
    display: flex;
    flex-wrap: wrap;
  }

  .container {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;

    @media (max-width: 992px) {
      max-width: 960px;
    }
    @media (max-width: 768px) {
      max-width: 720px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .gigi {
    @media (max-width: 850px) {
      img {
        width: 25vw;
      }
    }
    @media (max-width: 576px) {
      img {
        width: 35vw;
      }
    }
  }
`;
