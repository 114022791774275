import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import AppProvider from './hooks';
import { UrlProvider } from 'hooks/url';
import { GlobalStyle } from 'styles/GlobalStyle';
import './assets/fonts/Gotham-Black.ttf';

export const App = () => (
  <Router>
    <AppProvider>
      <UrlProvider>
        <Routes />
      </UrlProvider>
    </AppProvider>
    <GlobalStyle />
  </Router>
);
