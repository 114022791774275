const headerRequest = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods":
      "GET, POST, OPTIONS, PUT, PATCH, DELETE",
    "Content-Type": "multipart/form-data;charset=UTF-8",
  },
};
export default headerRequest;
