import React, { useEffect, useState } from 'react';
import { Loading, Template } from 'components';
import { useHistory } from 'react-router-dom';
import { ButtonGigi } from 'styles';

export function AdminMenu() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <ButtonGigi
            type='button'
            bgColor='red'
            onClick={() => history.push('admin/treinamentos')}
          >
            TREINAMENTOS
          </ButtonGigi>
        </Template>
      )}
    </>
  );
}
