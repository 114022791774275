import api from '../../api';

class InvoiceData {
  index() {
    return api.get(`administrativo/notafiscal`);
  }

  store(emissao: string) {
    return api.post(`administrativo/notafiscal/log`, { emissao });
  }
}

export default new InvoiceData();
