import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading, TutorialCard, Template } from 'components';
import { useToast } from 'hooks/toast';
import { apiDepartments, apiTutorials } from 'services/data';
import { ITutorialsDTO } from 'interfaces/Tutorials';
import { IDepartmentDTO } from 'interfaces/Departments';

import * as S from './styles';

interface FormProps {
  id_department: string;
  tutorial_title: string;
  url_video: string;
  file?: File;
}

export function AdminTreinamentos() {
  const [isLoading, setIsLoading] = useState(true);
  const [tutorials, setTutorials] = useState<ITutorialsDTO[]>([]);
  const [departments, setDepartments] = useState<IDepartmentDTO[]>([]);
  const { register, handleSubmit, setValue } = useForm<FormProps>();
  const { addToast } = useToast();

  async function fetchData() {
    const fetchTutorials = await apiTutorials.getAll();
    const fetchDepartments = await apiDepartments.getAll();

    setTutorials(fetchTutorials.data);
    setDepartments(fetchDepartments.data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function clearInputs() {
    setValue('tutorial_title', '');
    setValue('url_video', '');
    setValue('file', null);
  }

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      const file: any = data.file;

      const formData = new FormData();
      formData.append('id_department', data.id_department);
      formData.append('tutorial_title', data.tutorial_title);
      formData.append('url_video', data.url_video);
      formData.append('file', file[0]);

      const createTutorial = await apiTutorials.create(formData);

      if (createTutorial.status !== 201) {
        addToast({
          type: 'error',
          title: 'Error!',
          description: `${createTutorial.data}`,
        });
      }

      clearInputs();
      fetchData();

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `${createTutorial.data.message}`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error!',
        description: `${error}`,
      });
    }
  };

  async function handleDelete(id_tutorial: string) {
    const deleteTutorial = await apiTutorials.delete(id_tutorial);

    if (deleteTutorial.status !== 202) {
      addToast({
        type: 'error',
        title: 'Falha',
        description: 'Falha ao completar a ação!',
      });
    }

    fetchData();

    addToast({
      type: 'success',
      title: 'Êxito!',
      description: 'Registro deletado com êxito!',
    });
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.ItensContainer id='cadastro'>
            <S.TitleContent>Cadastrar novo tutorial</S.TitleContent>
            <S.Content>
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <S.Row>
                  <S.Col style={{ flex: 1 }}>
                    <label htmlFor='tutorial_title'>Título</label>
                    <S.Input
                      type='text'
                      name='tutorial_title'
                      id='tutorial_title'
                      required
                      autoComplete='off'
                      autoCorrect='off'
                      tabIndex={1}
                      ref={register({ required: true })}
                    />
                  </S.Col>
                </S.Row>
                <S.Row>
                  <S.Col style={{ flex: 1 }}>
                    <label htmlFor='url_video'>URL Vídeo</label>
                    <S.Input
                      type='text'
                      name='url_video'
                      id='url_video'
                      required
                      autoComplete='off'
                      autoCorrect='off'
                      tabIndex={2}
                      ref={register({ required: true })}
                    />
                  </S.Col>
                </S.Row>
                <S.Row>
                  <S.Col style={{ flex: 1 }}>
                    <label htmlFor='id_department'>Departamento</label>
                    <S.Select
                      name='id_department'
                      id='id_department'
                      required
                      tabIndex={3}
                      ref={register({ required: true })}
                    >
                      {departments.map((dp) => (
                        <option key={dp.id} value={dp.id}>
                          {dp.department}
                        </option>
                      ))}
                    </S.Select>
                  </S.Col>
                  <S.Col style={{ flex: 2 }}>
                    <label htmlFor='url_thumbnail'>Capa do vídeo</label>
                    <S.File
                      type='file'
                      name='file'
                      id='url_thumbnail'
                      tabIndex={4}
                      ref={register({ required: true })}
                    />
                  </S.Col>
                </S.Row>
                <S.Row style={{ justifyContent: 'flex-end' }}>
                  <S.Button type='submit'>
                    <S.SaveIcon />
                    Salvar
                  </S.Button>
                </S.Row>
              </S.Form>
            </S.Content>
          </S.ItensContainer>

          <S.ItensContainer id='listagem'>
            <S.TitleContent>Tutoriais cadastrados</S.TitleContent>
            <S.Content>
              <>
                {tutorials.length > 0 ? (
                  <>
                    {tutorials.map((tutorial) => (
                      <TutorialCard
                        key={tutorial.id}
                        id={tutorial.id}
                        tutorial_title={tutorial.tutorial_title}
                        url_thumbnail={tutorial.url_thumbnail}
                        url_video={tutorial.url_video}
                        status={tutorial.status}
                        created_at={tutorial.created_at}
                        updated_at={tutorial.updated_at}
                        handleDelete={() => handleDelete(tutorial.id)}
                      />
                    ))}
                  </>
                ) : (
                  <h4>Nenhum registro para ser exibido!</h4>
                )}
              </>
            </S.Content>
          </S.ItensContainer>
        </Template>
      )}
    </>
  );
}
