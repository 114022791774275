import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useToast } from 'hooks/toast';
import { Loading, Template } from 'components';
import { apiAngel } from 'services/data';
import * as S from './styles';

interface stateResponseProblema {
  id: number;
  atribuido_a: string;
  assunto: string;
  chamado: string;
  descricao: string;
  data: string;
}
interface stateResponseAtendimento {
  id: number;
  atribuido_a: string;
  descricao: string;
  chamado: string;
  data: Date;
}
interface ResponseAtendimento {
  id: number;
  rede_pessoa: {
    nome: string;
  };
  comentario: string;
  resolvido: string;
  atualizacao: Date;
}
interface Params {
  id: string;
}

const AnjoAtendimentoVer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [problema, setProblema] = useState<stateResponseProblema>();
  const [atendimentos, setAtendimentos] = useState<stateResponseAtendimento[]>(
    []
  );
  const { addToast } = useToast();
  const { id } = useParams<Params>();

  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await api.get(`/anjo/atendimento/${id}`);
        await apiAngel.show(Number(id)).then((response) => {
          setProblema({
            id: response.data.problema.id,
            atribuido_a:
              response.data.problema
                .rede_pessoa_crm_problema_id_pessoaTorede_pessoa.nome,
            assunto: response.data.problema.crm_fase.fase,
            chamado:
              response.data.problema.resolvido === 'sim' ? 'Fechado' : 'Aberto',
            descricao: response.data.problema.problema,
            data: format(
              parseISO(response.data.problema.acontece.toString()),
              'dd/MM/yyyy',
              {
                locale: ptBR,
              }
            ),
          });
          setAtendimentos(
            response.data.atendimento.map((item: ResponseAtendimento) => ({
              id: item.id,
              atribuido_a: item.rede_pessoa.nome,
              descricao: item.comentario,
              chamado: item.resolvido === 'sim' ? 'Fechado' : 'Aberto',
              data: format(
                parseISO(item.atualizacao.toString()),
                'dd/MM/yyyy',
                {
                  locale: ptBR,
                }
              ),
            }))
          );
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar os dados de atendimento',
          description: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [id, addToast]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          {problema && (
            <S.FormContainer>
              <S.InputContainer>
                <label>
                  <h1>Assunto:</h1>
                </label>
                <span>{problema.assunto}</span>
              </S.InputContainer>
              <S.InputContainer>
                <label>
                  <h1>Atendente:</h1>
                </label>
                <span>{problema.atribuido_a}</span>
              </S.InputContainer>
              <S.InputContainer>
                <label>
                  <h1>Descrição:</h1>
                </label>
                <span>{problema.descricao}</span>
              </S.InputContainer>
            </S.FormContainer>
          )}
          {atendimentos &&
            atendimentos.map((item) => (
              <div key={item.id}>
                <S.TitleContainer>
                  <S.Title>Atendimento da sua solicitação</S.Title>
                </S.TitleContainer>
                <S.FormContainer>
                  <S.InputContainer>
                    <label>
                      <h1>Atendente:</h1>
                    </label>
                    <span>{item.atribuido_a}</span>
                  </S.InputContainer>
                  <S.InputContainer>
                    <label>
                      <h1>Descrição:</h1>
                    </label>
                    <span>{item.descricao}</span>
                  </S.InputContainer>
                  <S.InputContainer>
                    <label>
                      <h1>Data:</h1>
                    </label>
                    <span>{item.data}</span>
                  </S.InputContainer>
                </S.FormContainer>
              </div>
            ))}
        </Template>
      )}
    </>
  );
};

export default AnjoAtendimentoVer;
