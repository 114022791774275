import React from 'react';
import Modal from 'react-modal';
import * as S from './styles';

interface ModalTutorialsProps {
  handleRequestClose: () => void;
  isOpen: boolean;
  video_url: string;
}

export function ModalTutorials({
  isOpen,
  handleRequestClose,
  video_url,
}: ModalTutorialsProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.60)',
          zIndex: 500,
        },
        content: {
          position: 'absolute',
          top: '3rem',
          left: '3rem',
          right: '3rem',
          bottom: '3rem',
          border: 'none',
          background: '#353535',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0.5rem',
          outline: 'none',
          padding: '1rem',
          overflowX: 'hidden',
          overflowY: 'hidden',
        },
      }}
    >
      <S.ButtonClose
        title='Clique aqui para fechar o vídeo'
        onClick={handleRequestClose}
      >
        <S.CloseIcon />
      </S.ButtonClose>
      <video
        controls
        controlsList='nodownload'
        style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
      >
        <source src={video_url} type='video/mp4' />
      </video>
    </Modal>
  );
}
