import React from "react";
import { useHistory } from "react-router-dom";

import botao_rid_locucao from "assets/images/locucao/botao_rid_locucao.svg";
import botao_editar_locucao from "assets/images/locucao/botao_editar_locucao.svg";
import * as S from "./styles";

interface Props {
  title: string;
}

const RadioCard: React.FC<Props> = ({ title }) => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.BotaoLocucao
        src={botao_rid_locucao}
        alt="Locução"
        onClick={() => history.push("/locucao")}
      />
      <S.BotaoEditar
        src={botao_editar_locucao}
        alt="Edição"
        onClick={() => history.push("/edicao")}
      />
    </S.Container>
  );
};

export default RadioCard;
