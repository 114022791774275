import styled from "styled-components";

export const Container = styled.div`
  select {
    display: none;
  }
  table {
    thead {
      tr:first-child {
        display: none;
      }
    }
  }
  .arrow {
    display: block;
    width: 0px;
    height: 0px;
    margin: 3px 0;
    border: 5px solid transparent;
  }
  .arrow.arrow-up {
    border-bottom-color: #000;
    border-top-width: 0px;
  }
  .arrow.arrow-down {
    border-top-color: #000;
    border-bottom-width: 0px;
  }
`;
