import React from "react";

import { SectorStyleProps } from "./interface";
import * as S from "./styles";

interface Props {
  sector: SectorStyleProps;
  children: React.ReactNode;
}

const Table = ({ sector, children }: Props) => {
  return <S.Container sector={sector}>{children}</S.Container>;
};

export default Table;
