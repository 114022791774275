import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import inoversidadeLogo from 'assets/images/icons/inoversidade-logo.svg';
import pecLogo from 'assets/images/icons/pec-logo.svg';
import paiLogo from 'assets/images/icons/pai-logo.svg';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';

const ComercialMenu = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi
              bgColor='red'
              onClick={() => history.push('anjo/atendimentos')}
            >
              ATENDIMENTOS
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() =>
                window.open(
                  'https://ead.redeinovadrogarias.com.br/login/index.php',
                  '_blank'
                )
              }
            >
              <img src={inoversidadeLogo} alt='Logo Inoversidade' />
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() =>
                window.open(
                  'https://www.febrafaranalysis.com.br/login.aspx?ReturnUrl=%2f',
                  '_blank'
                )
              }
            >
              <img src={pecLogo} alt='Logo PEC' />
            </ButtonGigi>
            <ButtonGigi
              bgColor='red'
              onClick={() =>
                window.open('https://paifebrafar.com.br/login', '_blank')
              }
            >
              <img src={paiLogo} alt='Logo PAI' />
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default ComercialMenu;
