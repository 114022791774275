import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--red-500);
  height: 50px;
  margin-top: 20px;
  border-radius: 8px;
`;

export const Title = styled.h3`
  color: #fff;
  padding: 20px;
`;

export const BotaoEditar = styled.img`
  position: absolute;
  right: 50px;
  width: 100px;
  cursor: pointer;
`;
export const BotaoLocucao = styled.img`
  position: absolute;
  right: 160px;
  width: 100px;
  cursor: pointer;
`;
