import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: grid;
  grid-template-areas:
    'HEADER'
    'CONTENT'
    'FOOTER';
  grid-template-rows: 50px 1fr 100px;
  grid-template-columns: 1fr;
  background: var(--gray-800);
`;

export const WrapperHeader = styled.header`
  grid-area: 'HEADER';
  background: var(--red-500);
`;

export const Header = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const NavigationControlls = styled.div`
  color: var(--white);
  zindex: 100;

  a {
    color: var(--white);
    text-decoration: none;

    transition: all 280ms ease-in-out;

    &:hover {
      filter: brightness(0.85);
    }
  }
`;

export const OrthersControlls = styled.div`
  display: flex;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: var(--white);

    transition: all 280ms ease-in-out;

    &:hover {
      filter: brightness(0.85);
    }
  }
`;

export const WrapperContent = styled.main`
  grid-area: 'CONTENT';
  background: var(--gray-800);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  margin: 0 auto;
`;

export const Footer = styled.footer`
  grid-area: 'FOOTER';
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: var(--red-500);

  img {
    width: max-content;
    height: calc(100px - 32px);
  }
`;
