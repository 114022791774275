import styled from 'styled-components';
import { FaSave, FaTimesCircle } from 'react-icons/fa';

export const ItensContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1120px;
  height: auto;
  padding: 1rem;
  margin: 2rem auto;
  border-radius: 1rem;
  box-shadow: 8px 0 12px rgba(0, 0, 0, 0.1);
  background: var(--gray-700);
  color: var(--white);

  &:first-child {
    margin-top: 2rem;
  }

  & + & {
    margin-top: 2rem;
  }
`;

export const TitleContent = styled.h2`
  font-size: 1.45rem;
  line-height: 1.75rem;
  color: var(--white);
  width: max-content;
  height: max-content;
  position: relative;
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  height: max-content;
  max-height: 600px;
  overflow-y: auto;
  padding-right: 1rem;

  ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.35);
    width: 7px;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-image: var(--warning);
    border-radius: 5px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 3rem;
  border: 1px solid var(--grey);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 1rem;
`;

export const Select = styled.select`
  width: 100%;
  height: 3rem;
  border: 1px solid var(--grey);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 1rem;
`;

export const File = styled.input`
  background: var(--white);
  height: 3rem;
  padding: 1rem;
  border: 1px solid var(--grey);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  padding: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-family: verdana;
  font-size: 1rem;
  color: var(--white);
  background: var(--success);
  border-radius: 0.5rem;
  border: none;

  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const SaveIcon = styled(FaSave)`
  width: 1.35rem;
  height: auto;
  fill: var(--white);
  margin-right: 0.75rem;
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, var(--red-500), var(--red-700));
  z-index: 50;
  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(FaTimesCircle)`
  width: 1.75rem;
  height: 1.75rem;
  fill: var(--grey);
`;
