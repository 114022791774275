import React, { useCallback, useState, useEffect } from "react";
import { Header, RadioCard, RadioPlayer, Footer } from "components";
import radio_logo from "assets/images/radio_logo.png";
import { useToast } from "hooks/toast";
import { useUrl } from "hooks/url";
import { apiLocucao } from "services/data";
import { radioConfig } from "utils";
import * as S from "./styles";

interface Response {
  id: number;
  userId?: number;
  repeat: number;
  start: Date;
  end: Date;
  url: string;
}

const Radio: React.FC = () => {
  const [data, setData] = useState<Response[]>([]);

  const { addToast } = useToast();
  const { urlAgencia } = useUrl();

  const fetchData = useCallback(async () => {
    try {
      apiLocucao.index().then((response) => {
        setData(response.data);
      });
    } catch (err) {
      addToast({
        type: "error",
        title: "Error",
        description: "Erro ao buscar as Locuções",
      });
    }
  }, [addToast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <S.Body>
      <Header imagePath={radio_logo} />
      <S.Container>
        {radioConfig &&
          radioConfig.map((item, key) => (
            <div key={key}>
              <RadioCard title={item.title} />
              <RadioPlayer
                audioId={item.id}
                id={item.id}
                url={item.url_radio}
                param={""}
                hts={item.hts}
                data={data}
                preload="auto"
              />
            </div>
          ))}
      </S.Container>
      {data.length > 0 &&
        data.map((item, key) => (
          <S.Audio key={key}>
            <RadioPlayer
              audioId=""
              id={`${item.id}`}
              url={`${urlAgencia}`}
              param={`uploads/locucao/${item.userId}/online/${item.url}`}
              hts=""
              data={[]}
              preload="auto"
            />
          </S.Audio>
        ))}
      <Footer />
    </S.Body>
  );
};

export default Radio;
