import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { IToken } from "interfaces/User";
import { useKeycloak } from "@react-keycloak/web";
import icomprasLogo from "assets/images/icons/icompras-logo.svg";
import jornalDeOfertasLogo from "assets/images/icons/jornal-de-ofertas-logo.svg";
import { statesReleasedForReservations } from "utils/constants/statesOnReservations";
import { apiFinancingStatus } from "services/data";
import { Loading, Template } from "components";
import { ButtonGigi } from "styles";

const ComercialMenu = () => {
  const { keycloak } = useKeycloak();
  const user: IToken = jwtDecode(keycloak.token || "");

  const [isLoading, setIsLoading] = useState(true);
  const [financingStatus, setFinancingStatus] = useState(false);
  const history = useHistory();

  async function fetchFinancingStatus() {
    const requestFinancingStatus = await apiFinancingStatus.getStatus();
    setFinancingStatus(requestFinancingStatus.data.debito);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchFinancingStatus();
  }, []);

  const isClientFarmaHub = useMemo(() => {
    if (user) {
      if (user.isFarmaHub) {
        if (Boolean(user.isFarmaHub) === true) {
          return true;
        }
      }
      return false;
    }

    return false;
  }, [user]);

  const PermissionFinancialAndReservationPerState = useMemo(() => {
    if (isClientFarmaHub) {
      return true;
    } else if (
      statesReleasedForReservations.includes(user.estado) &&
      !financingStatus
    ) {
      return false;
    } else {
      return true;
    }
  }, [user, financingStatus, isClientFarmaHub]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className="button-content">
            <ButtonGigi
              bgColor="red"
              onClick={() =>
                window.open(
                  "https://www.raconecta.com.br/portal/inova",
                  "_blank"
                )
              }
            >
              <img src={icomprasLogo} alt="Logo ICompras" />
            </ButtonGigi>
            <ButtonGigi
              bgColor={isClientFarmaHub ? "disabled" : "red"}
              disabled={isClientFarmaHub}
              onClick={() => history.push("comercial/ondecomprar")}
            >
              Onde Comprar
            </ButtonGigi>

            <ButtonGigi
              bgColor={
                PermissionFinancialAndReservationPerState ? "disabled" : "red"
              }
              disabled={PermissionFinancialAndReservationPerState}
              onClick={() => history.push("comercial/jornalofertas")}
            >
              <img src={jornalDeOfertasLogo} alt="Logo Jornal de ofertas" />
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default ComercialMenu;
