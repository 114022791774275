import React, { useCallback, useEffect, useState } from "react";
import {
  FaPlayCircle,
  FaPauseCircle,
  FaVolumeUp,
  FaRegPlayCircle,
  FaBolt,
  FaFacebookF,
} from "react-icons/fa";
import * as S from "./styles";
import axios from "axios";
import { radioConfig } from "utils";

interface Response {
  id: number;
  userId?: number;
  repeat: number;
  start: Date;
  end: Date;
  url: string;
}

interface Props {
  audioId: string;
  id: string;
  url: string;
  param: string;
  hts: string;
  data: Response[];
  preload: string;
}

interface IMusic {
  image: string;
  song: string;
}

const RadioPlayer: React.FC<Props> = ({
  audioId,
  id,
  url,
  param,
  hts,
  data,
  preload,
}) => {
  const [music, setMusic] = useState<IMusic>();
  const [time, setTime] = useState<Array<number>>([]);
  const [interv, setInterv] = useState<Array<number>>([]);
  const [exec, setExec] = useState(false);

  useEffect(() => {
    const fetchMusic = async () => {
      await axios
        .get(
          `https://minharadioonline.net/last/music.php?hts=hts${hts}&porta=${audioId}`,
          {
            headers: {
              accept: "*/*",
            },
          }
        )
        .then((musicData) => {
          const partMusic = musicData.data.split("||IMAGE||");
          setMusic({ song: partMusic[0], image: partMusic[1] });
        });
    };
    if (hts && audioId) {
      fetchMusic();
      setInterval(() => {
        fetchMusic();
      }, 30000);
    }
  }, [hts, audioId]);

  const locucaoStart = useCallback(async () => {
    const interv_: Array<number> = [];
    const time_: Array<number> = [];

    for (const e of data) {
      const audio = document.getElementById(`id${id}`);
      const locucao = document.getElementById(`id${e.id}`);
      const duration = Math.ceil((locucao as HTMLAudioElement).duration) * 1000;
      const repeat = e.repeat * 1000 * 60;
      interv_.push(
        await window.setInterval(async () => {
          if (!exec) {
            setExec(true);
            await (audio as HTMLAudioElement).pause();
            await (locucao as HTMLAudioElement).load();
            await (locucao as HTMLAudioElement).play();
            time_.push(
              await window.setTimeout(async () => {
                await (locucao as HTMLAudioElement).pause();
                await (audio as HTMLAudioElement).load();
                await (audio as HTMLAudioElement).play();
                /* console.log("Voltou"); */
              }, duration)
            );
            setExec(false);
          }
          /* console.log("executou"); */
        }, repeat)
      );
    }
    setTime(time_);
    setInterv(interv_);
  }, [data, id, exec]);

  const handlePause = useCallback(async () => {
    for (const e of radioConfig) {
      const audio = document.getElementById(`id${e.id}`);
      await (audio as HTMLAudioElement).pause();
    }
    for (const e of data) {
      const locucao = document.getElementById(`id${e.id}`);
      await (locucao as HTMLAudioElement).pause();
    }
    for (const i of time) {
      await clearTimeout(i);
    }
    for (const i of interv) {
      await clearTimeout(i);
    }
  }, [data, time, interv]);

  const handlePlay = useCallback(async () => {
    // TO DO - ENTENDER PORQUE SOMENTE LOUNGE E GOSPEL NÃO RODA COM ESTE CÓDIGO ATIVO. 21/09/21.
    /* handlePause(); */
    const audio = document.getElementById(`id${id}`);
    await (audio as HTMLAudioElement).load();
    await (audio as HTMLAudioElement).play();
    locucaoStart();
  }, [id, locucaoStart]);

  return (
    <>
      <S.Container>
        <audio controls preload={preload} id={`id${id}`}>
          <source
            src={`${url}${param.length > 0 ? `/${param}` : ""}`}
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
        {music && (
          <S.Music>
            <S.MusicImage style={{ backgroundImage: `url(${music.image})` }} />
            <S.MusicText>
              <span>Tocando Agora:</span>
              <div>
                <p>{music.song}</p>
              </div>
            </S.MusicText>
          </S.Music>
        )}
        <S.Volume type="button">
          <FaVolumeUp />
        </S.Volume>
        <S.Play type="button" onClick={handlePlay}>
          <FaPlayCircle />
        </S.Play>
        <S.Pause type="button" onClick={handlePause}>
          <FaPauseCircle />
        </S.Pause>
        <S.Player>
          <a
            href={`https://app.kshost.com.br/player/${audioId}/realplayer.rm`}
            target="_blank"
            rel="noreferrer"
          >
            <FaRegPlayCircle />
          </a>
          <a
            href={`https://app.kshost.com.br/player/${audioId}/mediaplayer.asx`}
            target="_blank"
            rel="noreferrer"
          >
            <FaPlayCircle />
          </a>
          <a
            href={`https://app.kshost.com.br/player/${audioId}/winamp.pls`}
            target="_blank"
            rel="noreferrer"
          >
            <FaBolt />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=http://redeinovadrogarias.com.br/hotsite"
            onClick={(e) => {
              window.open(
                "https://www.facebook.com/sharer/sharer.php?u=http://redeinovadrogarias.com.br/hotsite",
                "",
                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
              );
              return false;
            }}
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
        </S.Player>
        <div className="containerAnimation">
          <div className="animation">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </S.Container>
    </>
  );
};

export default RadioPlayer;
