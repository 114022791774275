import React from "react";
import Lottie from "react-lottie";
import loadingLottie from "lotties/loadingInova.json";
import * as S from "./styles";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loading: React.FC = () => {
  return (
    <S.Container>
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        style={{ background: "#fff" }}
      />
    </S.Container>
  );
};

export default Loading;
