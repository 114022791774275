import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 1268px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  color: var(--white);
  font-size: 2rem;

  @media (max-width: 1268px) {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  color: var(--grey);
  background: var(--gray-700);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 10px;

  @media (max-width: 1268px) {
    flex-direction: column;
  }

  h5 {
    margin-bottom: 0.25rem;
  }

  div {
    margin: 0.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InputRadioCustomize = styled.input`
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
  border: 3px solid var(--red-500);
  background-color: var(--red-50);
  border-radius: 50%;
  appearance: unset;

  transition: background-color 350ms;

  &:checked {
    background-color: var(--red-700);
  }
`;

export const SubContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  background: var(--gray-700);
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const WrapperForm = styled.div`
  display: grid;
  grid-template-columns: 12rem 12rem;
  gap: 1rem;
  width: 100%;
  background: transparent;
  color: var(--white);

  div {
    select {
      width: 12rem;
      height: 3rem;
      border: 2px solid var(--red-500);
      border-radius: 1rem;
      padding: 0.5rem;
      background-color: var(--red-50);
      font-size: 1rem;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-weight: 500;
      margin: 0.5rem 0;
    }
  }
`;

export const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey);
  font-weight: bold;
  width: 100%;
  margin-top: 1rem;
  background: var(--gray-700);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey);

  & + div {
    margin-top: 1rem;
  }

  input {
    height: 2rem;
    width: 2rem;
    background-color: var(--red-50);
    appearance: unset;
    border: 3px solid var(--red-500);
    border-radius: 0.5rem;
    align-self: center;
    cursor: pointer;
    position: relative;
    transition: all 350ms;

    &:checked {
      background-color: var(--red-400);
      color: #fff;

      &:before {
        content: 'X';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1.5rem;
      }
    }
  }

  label {
    height: auto;
    width: 100%;
    align-self: center;
    margin: 1rem 0 0 1rem;
    font-size: 1rem;
    text-align: justify;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
  }

  @media (max-width: 1268px) {
    margin: 0 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const IpAddressContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-hover);
`;

export const IpAddressText = styled.p``;
