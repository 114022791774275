import React, { useState, useEffect, useMemo, useCallback } from "react";
import type { CellProps } from "react-table";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { apiBillet } from "services/data";
import { useToast } from "hooks/toast";
import { useUrl } from "hooks/url";
import { Loading, Table, CustomizedTable, Template } from "components";
import { SectorStyleProps } from "components/CustomizedTable/interface";
import { CiBarcode } from "react-icons/ci";
import { Button } from "styles";
import * as S from "./styles";

interface Response {
  id: number;
  datavencimento: Date;
  datapagamento: Date;
  fin_conta: {
    descricao: string;
    fluxo: string;
    unitario: number;
  };
  valor: number;
  valorpago: number;
  quantidade: number;
  fin_boleto: {
    nosso_numero: number;
  };
  fin_boleto_kexion: {
    file_url: string;
  };
}

const Financeiro = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [boleto, setBoleto] = useState<Response[]>([]);
  const [fluxo, setFluxo] = useState<Response[]>([]);
  const { addToast } = useToast();
  const { urlCrm } = useUrl();

  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await api.get(`/administrativo/financeiro`);
        await apiBillet.index().then((response) => {
          setBoleto(
            response.data.boleto.map((item: Response) => ({
              id: item.id,
              descricao: item.fin_conta.descricao,
              quantidade: item.quantidade,
              unitario: item.fin_conta.unitario,
              valor: item.valor,
              datavencimento: format(
                parseISO(item.datavencimento.toString()),
                "dd/MM/yyyy",
                {
                  locale: ptBR,
                }
              ),
              venc: format(parseISO(item.datavencimento.toString()), "yyyyMM", {
                locale: ptBR,
              }),
              nosso_numero: item.fin_boleto
                ? item.fin_boleto.nosso_numero
                : item.fin_boleto_kexion.file_url,
            }))
          );
          setFluxo(
            response.data.fluxo.map((item: Response) => ({
              id: item.id,
              descricao: item.fin_conta.descricao,
              quantidade: item.quantidade,
              unitario: item.fin_conta.unitario,
              valor: item.valor,
              datavencimento: format(
                parseISO(item.datavencimento.toString()),
                "dd/MM/yyyy",
                {
                  locale: ptBR,
                }
              ),
              datapagamento: format(
                parseISO(item.datapagamento.toString()),
                "dd/MM/yyyy",
                {
                  locale: ptBR,
                }
              ),
              valorpago: item.valorpago,
            }))
          );
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Erro ao buscar Boletos",
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [addToast]);

  const handleLog = useCallback(
    ({
      descricao,
      quantidade,
      unitario,
      valor,
      datavencimento,
      venc,
      nosso_numero,
    }) => {
      async function fetchData() {
        const quant = `${quantidade}`;
        const unit = `${unitario}`;
        const val = `${valor}`;
        try {
          await apiBillet
            .store({
              descricao,
              quantidade: quant,
              unitario: unit,
              valor: val,
              datavencimento,
            })
            .then(() => {
              if (nosso_numero.indexOf("http") === -1) {
                const financeiroUrl = `${urlCrm}/boleto`;
                window.open(
                  `${financeiroUrl}/${venc}/${nosso_numero}.pdf`,
                  "_blank"
                );
              } else {
                window.open(`${nosso_numero}`, "_blank");
              }
            });
        } catch (err) {
          addToast({
            type: "error",
            title: "Erro ao realizar o Log",
            description: (err as Error).message,
          });
        }
      }
      fetchData();
    },
    [addToast, urlCrm]
  );

  const columnBoletos = useMemo(
    () => [
      {
        Header: "Contas",
        columns: [
          {
            Header: "Conta",
            accessor: "descricao",
          },
          {
            Header: "Quantidade",
            accessor: "quantidade",
          },
          {
            Header: "Valor Unitário",
            accessor: "unitario",
          },
          {
            Header: "Valor",
            accessor: "valor",
          },
          {
            Header: "Vencimento",
            accessor: "datavencimento",
          },
        ],
      },
      {
        Header: "Operações",
        columns: [
          {
            Header: "Gerar Boleto",
            Cell: ({ cell }: CellProps<{}>) => (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleLog({ ...cell.row.original })}
              >
                <CiBarcode size={32} color="white" />
              </button>
            ),
          },
        ],
      },
    ],
    [handleLog]
  );

  const columnFluxos = useMemo(
    () => [
      {
        Header: "Contas",
        columns: [
          {
            Header: "Conta",
            accessor: "descricao",
          },
          {
            Header: "Quantidade",
            accessor: "quantidade",
          },
          {
            Header: "Valor Unitário",
            accessor: "unitario",
          },
          {
            Header: "Valor",
            accessor: "valor",
          },
          {
            Header: "Vencimento",
            accessor: "datavencimento",
          },
          {
            Header: "Pagamento",
            accessor: "datapagamento",
          },
          {
            Header: "Valor Pago",
            accessor: "valorpago",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.ButtonContainer>
            <Button bgColor="default">À PAGAR</Button>
          </S.ButtonContainer>
          <CustomizedTable sector={SectorStyleProps["RED"]}>
            <Table columns={columnBoletos} data={boleto} />
          </CustomizedTable>
          <S.ButtonContainer>
            <Button bgColor="success">PAGOS</Button>
          </S.ButtonContainer>
          <CustomizedTable sector={SectorStyleProps["RED"]}>
            <Table columns={columnFluxos} data={fluxo} />
          </CustomizedTable>
        </Template>
      )}
    </>
  );
};

export default Financeiro;
