import styled from "styled-components";

export const Container = styled.div`
  background: #010814;
  img {
    width: 100%;
  }
`;

export const Body = styled.main`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  img.aovivo {
    width: 40%;
    margin: 0 auto;
  }
`;

export const Header = styled.header`
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  img.setas{
    height: 140px;
    width: 120px;
  }
  div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    button {
      cursor: pointer;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #010814;
      border: 0;
      outline: 0;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      img {
        height: 40px;
        width: 44px;
      }
    }
  }
`;

export const BarRidRadio = styled.div`
  background-color: #FF574b;
  border-radius: 15px;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  img {
    height: 40px;
  }
`;

export const BarAudios = styled.div`
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  div {
    margin: 5px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e6e6e6;
    border-radius: 15px;
    div {
      padding: 5px;
      margin: 0;
      img {
        height: 50px;
      }
    }
    div.audio {
      width: 100%;
      audio {
        width: 100%;
        outline: 0;
      }
    }
    div.remover {
      img {
        cursor: pointer;
      }
    }
  }
`;