import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import carr01 from 'assets/images/carrousel/banner1.svg';
import carr02 from 'assets/images/carrousel/banner2.svg';
import carr04 from 'assets/images/carrousel/banner4.svg';
import carr05 from 'assets/images/carrousel/banner5.svg';
import * as S from './styles';

const Footer: React.FC = () => {
  const settings = {
    className: '',
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <S.Container>
      <Slider {...settings} className='carrousel'>
        <S.CarrouselItem>
          <S.CarrouselImage src={carr01} />
        </S.CarrouselItem>
        <S.CarrouselItem>
          <S.CarrouselImage src={carr02} />
        </S.CarrouselItem>
        <S.CarrouselItem>
          <S.CarrouselImage src={carr04} />
        </S.CarrouselItem>
        <S.CarrouselItem>
          <S.CarrouselImage src={carr05} />
        </S.CarrouselItem>
      </Slider>
    </S.Container>
  );
};

export default Footer;
