import styled from 'styled-components';

interface ISummaryCard {
  bgColor: string;
}

export const TitleContainer = styled.div`
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  @media (max-width: 1268px) {
    width: 90%;
  }
`;

export const Title = styled.h1`
  color: var(--white);
  font-size: 24px;
  @media (max-width: 1268px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: var(--white);
  border-radius: 0.5rem;
  border: 3px solid var(--red-500);
  margin-top: 0.65rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1268px) {
    width: 90%;
  }

  label {
    width: 200px;
    height: 48px;
    background-color: var(--red-500);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1268px) {
      width: 100px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 5px;
    }

    h1 {
      color: var(--white);
      font-size: 22px;

      @media (max-width: 1268px) {
        font-size: 14px;
      }
    }
  }

  span {
    background-color: var(--white);
    border: 0;
    border-radius: 0 0.5rem 0.5rem 0;
    width: 84%;
    font-size: 1.3em;
    display: flex;
    align-items: center;
  }
`;

export const ButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  @media (max-width: 1268px) {
    width: 90%;
  }
`;

export const SummaryContainer = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1268px) {
    flex-wrap: wrap;
  }
`;

export const SummaryCard = styled.div<ISummaryCard>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  height: 63px;
  width: 213px;

  @media (max-width: 1268px) {
    margin-top: 10px;
    width: 90%;
  }
`;

export const SummaryTitle = styled.h1`
  font-size: 16px;
  color: var(--white);
  margin: 5px 0;
`;
