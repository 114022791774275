import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GrAssistListening } from 'react-icons/gr';
import { FaRegFileAudio } from 'react-icons/fa';
import { useSpeechSynthesis } from 'react-speech-kit';
import { useForm } from 'react-hook-form';
import Recorder from 'react-mp3-recorder';
import ReactAudioPlayer from 'react-audio-player';
import { useToast } from 'hooks/toast';
import { apiLocucao } from 'services/data';
import { useUrl } from 'hooks/url';
import { repeatLocucao } from 'utils';
import * as S from './styles';
import fundo from 'assets/images/locucao/fundo.png';
import setas from 'assets/images/locucao/setas.png';
import voltar from 'assets/images/locucao/voltar.svg';
import radio from 'assets/images/locucao/radio.svg';

interface Response {
  id?: number;
  userId?: number;
  repeat: number;
  start: Date;
  end: Date;
  url: string;
}

interface IRepeat {
  minute: number;
  text: string;
}

const Edicao: React.FC = () => {
  const { urlAgencia } = useUrl();
  const [text, setText] = useState('');
  const [repeat, setRepeat] = useState<Array<IRepeat>>();
  const [url, setURL] = useState('');
  const history = useHistory();
  const { supported } = useSpeechSynthesis();
  const { addToast } = useToast();
  const { register, handleSubmit } = useForm();
  const fetchData = useCallback(async () => {
    try {
      const response = await apiLocucao.index();
      const repeatStore = response.data.map((item: Response) => item.repeat);
      const aux = repeatLocucao.filter((item) => {
        return repeatStore.indexOf(item.minute) === -1;
      });
      if (aux.length === 0) {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Remova uma locução para poder gravar uma nova!',
        });
      }
      setRepeat(aux);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao buscar a Locução',
      });
    }
  }, [addToast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onRecordingComplete = useCallback(
    async (blob) => {
      try {
        const fileData = new FormData();
        fileData.append('files', blob, 'aquivo.mp3');
        apiLocucao.upload(fileData).then((response) => {
          setURL(response.data.url);
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao gravar a locução, envie novamente por favor!',
          description: (err as Error).message,
        });
      }
    },
    [addToast]
  );

  const onRecordingError = useCallback(
    (err) => {
      console.log('error recording', err);
      if (url) {
        window.URL.revokeObjectURL(url);
      }
      setURL(url);
    },
    [url]
  );

  const onSpeech = useCallback(async () => {
    if (!text) {
      addToast({
        type: 'error',
        title: 'Locução',
        description: 'Escreva um texto antes de gerar o áudio',
      });
    } else {
      try {
        apiLocucao.uploadText({ text }).then((response) => {
          setURL(response.data.url);
        });
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao gerar o aúdio da locução',
        });
      }
    }
  }, [text, addToast]);

  const onFileUpload = useCallback(
    async (e) => {
      try {
        const fileData = new FormData();
        const file = new Blob(e.target.files);
        fileData.append('files', file, 'aquivo.mp3');
        apiLocucao.upload(fileData).then((response) => {
          setURL(response.data.url);
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao enviar a locução',
          description: (err as Error).message,
        });
      }
    },
    [addToast]
  );

  const onSubmit = useCallback(
    async (body) => {
      if (!body.url) {
        addToast({
          type: 'error',
          title: 'Locução',
          description:
            'Gere um áudio, faça uma gravação ou envie um arquivo mp3 de áudio',
        });
      } else {
        try {
          apiLocucao.store({ ...body }).then(() => {
            addToast({
              type: 'success',
              title: 'Locução',
              description: 'Locução registrada com sucesso!',
            });
            history.push('/edicao');
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Error',
            description: 'Erro ao cadastrar a Locução',
          });
        }
      }
    },
    [addToast, history]
  );

  return (
    <S.Container>
      <S.Header>
        <img src={setas} alt='Setas Locução' className='setas' />
        <div>
          <button>
            <img
              src={voltar}
              alt='Voltar Locução'
              onClick={() => history.push('/radio')}
              className='voltar'
            />
            VOLTAR
          </button>
        </div>
      </S.Header>
      <S.Body>
        <img src={radio} alt='Locução ao Vivo' className='aovivo' />

        {!supported && <p>Seu navegador não suporta este recurso.</p>}
        {supported && (
          <>
            <textarea
              value={text}
              onChange={(event) => setText(event.target.value)}
              placeholder='Digite o seu texto:'
            />
            <div>
              <button>
                Gerar Áudio
                <div className='styles_button__3Vugn' onClick={onSpeech}>
                  <GrAssistListening color='white' />
                </div>
              </button>
              <span></span>
              <button>
                Clique e segure para realizar uma gravação.
                <Recorder
                  onRecordingComplete={onRecordingComplete}
                  onRecordingError={onRecordingError}
                />
              </button>
              <span></span>
              <button>
                Enviar um áudio
                <label htmlFor='audioFile' className='styles_button__3Vugn'>
                  <FaRegFileAudio />
                </label>
                <input
                  type='file'
                  name='audio'
                  id='audioFile'
                  onChange={onFileUpload}
                  accept='.mp3,audio/mpeg3'
                />
              </button>
            </div>
          </>
        )}
        {url && (
          <div>
            <ReactAudioPlayer
              src={`${urlAgencia}${url}`}
              controls
              style={{ width: '100%' }}
            />
          </div>
        )}
      </S.Body>
      <S.Record onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='url' value={url} ref={register} />
        <p>Frequência de repetição</p>
        <div>
          <div>
            <span>
              <p>Repetir</p>
              <select name='repeat' ref={register({ required: true })} required>
                <option></option>
                {repeat &&
                  repeat.map((repeat, key) => (
                    <option key={key} value={repeat.minute}>
                      {repeat.text}
                    </option>
                  ))}
              </select>
            </span>
          </div>
          <div>
            <span>
              <p>Dias: </p>
              <label>de</label>
              <input
                type='date'
                name='start'
                ref={register({ required: true })}
                required
              />
              <label>a</label>
              <input
                type='date'
                name='end'
                ref={register({ required: true })}
                required
              />
            </span>
          </div>
        </div>
        <div>
          <button type='submit'>Enviar</button>
        </div>
      </S.Record>
      <img src={fundo} alt='Fundo locução' />
    </S.Container>
  );
};

export default Edicao;
