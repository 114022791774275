import styled from 'styled-components';
import { FaPlay } from 'react-icons/fa';

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 305px;
  height: max-content;
  min-height: 300px;
  background: var(--gray-700);
  padding: 0.75rem;
  border-radius: 0.5rem;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 310px;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 255px;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  min-height: 180px;
  max-height: 180px;
  object-fit: cover;
  object-position: center;
  aspectio-ratio: 16 / 9;
  border-radius: 0.5rem;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.span`
  display: inline-block;
  width: max-content;
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--grey);
`;

export const ButtonPlay = styled.button`
  position: absolute;
  bottom: -0.75rem;
  right: -0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, var(--red-500), var(--red-400));
  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
    transform: scale(1.15);
  }
`;

export const PlayIcon = styled(FaPlay)`
  width: 1.35rem;
  height: 1.35rem;
  fill: #fff;
`;
