import React from 'react';
import { Footer, Header } from 'components';
import { GoDesktopDownload } from 'react-icons/go';
import TelevisionApi from 'services/data/Television';

import tv_logo from '../../assets/images/tv_logo.png';

import * as S from './styles';

const Television: React.FC = () => {
  async function registerLogger() {
    await TelevisionApi.index();
  }

  return (
    <S.Body>
      <Header imagePath={tv_logo} />
      <S.Container>
        <S.Player width='720' height='auto' controls={false} loop autoPlay>
          <source
            src='https://rid-growup.s3.amazonaws.com/tv/ridtv.mp4'
            type='video/mp4'
          />
        </S.Player>
      </S.Container>
      <S.Container>
        <a
          download='rid_tv'
          href='https://rid-growup.s3.amazonaws.com/tv/ridtv.mp4'
          rel='noreferrer'
          title='Clique aqui para realizar o download do VT acima'
          onClick={registerLogger}
        >
          <GoDesktopDownload size='2rem' />
          Realizar download
        </a>
      </S.Container>
      <Footer />
    </S.Body>
  );
};

export default Television;
