import React, { useEffect, useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak, { KeycloakInstance } from "keycloak-js";
import axios from "axios";
import { ToastProvider } from "./toast";

const eventLogger = (event: unknown, error: unknown) => {
};

const tokenLogger = (tokens: unknown) => {
};

const AppProvider: React.FC = ({ children }) => {
  const [keycloak, setKeycloak] = useState<KeycloakInstance>();
  useEffect(() => {
    const verify = async () => {
      let url =
        "https://auth.redeinovadrogarias.com.br:8015/auth/realms/licenciado";
      try {
        await axios({
          method: "get",
          url,
          timeout: 1000,
        });
        url = "https://auth.redeinovadrogarias.com.br:8015/auth/";
        setKeycloak(
          Keycloak({
            realm: "licenciado",
            url,
            clientId: "licenciado",
          })
        );
      } catch (error) {
        url = "https://auth1.redeinovadrogarias.com.br:8015/auth/";
        setKeycloak(
          Keycloak({
            realm: "licenciado",
            url,
            clientId: "licenciado",
          })
        );
      }
    };
    verify();
  }, []);

  return (
    <>
      {keycloak && (
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
        >
          <ToastProvider>{children}</ToastProvider>
        </ReactKeycloakProvider>
      )}
    </>
  );
};

export default AppProvider;
