import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  padding: 1rem 1rem;
  height: max-content;
  border-radius: 0.5rem;
  background: var(--gray-700);
  color: var(--white);
`;

export const Row = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 1rem;
  margin-bottom: 1rem;

  h4 {
    margin-bottom: 0.5rem;
  }
`;

export const RowDouble = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;

  div {
    &:nth-child(2) {
      margin-left: 2rem;
    }

    h4 {
      margin-bottom: 0.5rem;
    }
  }
`;
