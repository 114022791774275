import { apiAgencia } from '../../api';
import { headerRequest } from "utils";

class LocucaoData {
  index() {
    return apiAgencia.get(`users/locucao`);
  }
  store(body: string) {
    return apiAgencia.post(`users/locucao`, body);
  }
  upload(body: any) {
    return apiAgencia.patch(`users/locucao/upload`, body, headerRequest);
  }
  uploadText(text: any) {
    return apiAgencia.patch(`users/locucao`, text);
  }
  destroy(id: number) {
    return apiAgencia.delete(`/users/locucao/${id}`)
  }
}

export default new LocucaoData();
