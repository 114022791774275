import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--white);
  height: 350px;
  width: 235px;
  border-radius: 0.7rem;
  padding: 1.2rem;
  margin: 1rem 0.5rem;
  border: 5px solid var(--red-500);
`;

export const Image = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 0.5rem;
`;

export const Text = styled.p`
  color: #000;
  text-align: center;
  font-weight: 500;
`;

export const Legend = styled.span`
  color: #000;
  font-size: 0.8em;
  text-align: center;
`;
