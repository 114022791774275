import styled from 'styled-components';

export const Container = styled.div`
  background: #010814;
  img {
    width: 100%;
  }
`;

export const Body = styled.main`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;

  img.aovivo {
    width: 40%;
    margin: 0 auto;
  }

  textarea {
    height: 150px;
    border: 0.5rem;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0;
    line-height: 1.8rem;
    font-size: 1rem;
    outline: 0;
  }
  select {
    margin: 0.7rem 0;
    border: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 10px;
    outline: 0;
  }

  div.rhap_main {
    width: 100%;
  }

  div {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;

    audio {
      outline: 0;
    }

    span {
      width: 1.5rem;
    }

    button {
      width: 100%;
      padding: 1rem;
      background-color: var(--red-500);
      border: 2px solid var(--white);
      border-radius: 0.5rem;
      color: var(--white);
      outline: 0;
      font-size: 1rem;

      div.styles_button__3Vugn,
      label.styles_button__3Vugn {
        margin-top: 0.7rem;
        width: 3rem;
        height: 3rem;

        svg {
          width: 2rem;
          height: 2rem;
          margin: 0 auto;

          path {
            stroke: var(--white);
          }
        }

        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    label {
      font-size: 1rem;
      color: var(--white);
      font-weight: 450px;
      width: 4.25rem;
      margin: 0 auto;
    }
    input {
      width: 80%;
      display: none;
    }
    div {
      margin: 0 auto;
      width: 4rem;
    }
  }
`;

export const Header = styled.header`
  width: 90%;
  margin: 0 auto;
  padding-top: 1.5rem;
  display: flex;

  img.setas {
    height: 140px;
    width: 120px;
  }

  div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    button {
      cursor: pointer;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #010814;
      border: 0;
      outline: 0;
      color: var(--white);
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      img {
        height: 3.8rem;
        width: 3rem;
      }
    }
  }
`;

export const Record = styled.form`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  color: var(--white);
  font-weight: bold;
  text-align: center;

  p {
    margin: 0.5rem 0;
  }

  div {
    width: 100%;
    display: flex;
    text-align: left;

    div {
      display: flex;
      justify-content: center;

      label {
        margin: 0.5rem;
      }

      select,
      input {
        padding: 0.5rem;
        background-color: var(--red-500);
        border: 2px solid var(--white);
        border-radius: 0.5rem;
        color: var(--white);
        outline: 0;
        font-weight: bold;
        font-size: 16px;
      }
    }
    
    button {
      padding: 0.75rem;
      background-color: var(--red-500);
      border: 2px solid var(--white);
      border-radius: 5px;
      color: var(--white);
      outline: 0;
      font-weight: bold;
      font-size: 1rem;
      margin: 1.5rem auto;
    }
  }
`;
