import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

interface UrlContextData {
  urlCrm: string;
  urlPrisma: string;
  urlAgencia: string;
}

const UrlContext = createContext<UrlContextData>({} as UrlContextData);

const UrlProvider: React.FC = ({ children }) => {
  const [urlCrmA, setUrlCrmA] = useState("");
  const [urlPrismaA, setUrlPrismaA] = useState("");
  const [urlAgenciaA, setUrlAgenciaA] = useState("");

  useEffect(() => {
    const verify = async () => {
      try {
        await axios({
          method: "get",
          url: "https://auth.redeinovadrogarias.com.br:8015/auth/realms/licenciado",
          timeout: 1000,
        });
        setUrlCrmA("https://sistema.redeinovadrogarias.com.br:8000");
        setUrlPrismaA("https://sistema.redeinovadrogarias.com.br:8011");
        setUrlAgenciaA("https://auth.redeinovadrogarias.com.br:8012");
      } catch (error) {
        setUrlCrmA("https://sistema1.redeinovadrogarias.com.br:8000");
        setUrlPrismaA("https://sistema1.redeinovadrogarias.com.br:8011");
        setUrlAgenciaA("https://auth1.redeinovadrogarias.com.br:8012");
      }
    };
    verify();
    // setUrlCrmA("http://localhost");
    // setUrlPrismaA("http://localhost:8011");
    // setUrlAgenciaA("http://localhost:8012");
  }, []);

  return (
    <UrlContext.Provider
      value={{
        urlCrm: urlCrmA,
        urlPrisma: urlPrismaA,
        urlAgencia: urlAgenciaA,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
};

function useUrl(): UrlContextData {
  const context = useContext(UrlContext);

  if (!context) {
    throw new Error("useUrl must be used within an UrlProvider");
  }

  return context;
}

export { UrlProvider, useUrl };
