import styled from 'styled-components';
import { FaEye, FaPencilAlt, FaTrash } from 'react-icons/fa';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background: var(--gray-800);

  &:first-child {
    margin-top: 1rem;
  }

  & + & {
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 180px;
  height: auto;
  border-radius: 0.5rem;
  object-fit: cover;
  object-position: center;
  aspectio-radio: 16 / 9;
`;

export const InfosContainer = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > span {
    font-family: verdana;

    > a {
      width: max-content;
      height: max-content;
      padding: 0.3rem 0.5rem;
      text-decoration: none;
      font-family: verdana;
      font-size: 0.85rem;
      color: var(--white);
      border-radius: 0.5rem;
      background: var(--primary);

      transition: all 350ms ease;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  &:last-child {
    margin-left: auto;
  }
`;

export const View = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem;
  text-decoration: none;
  font-family: verdana;
  font-size: 0.85rem;
  color: var(--white);
  border-radius: 0.5rem;
  background: var(--primary);

  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

interface IButtonProps {
  bgColor: string;
}

export const Button = styled.button<IButtonProps>`
  height: 100%;
  width: 4rem;
  background: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: none;

  transition: all 350ms ease;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    background: var(--secondary);
    cursor: not-allowed;

    &:hover {
      filter: brightness(1);
    }
  }
`;

export const EyeIcon = styled(FaEye)`
  width: 1rem;
  height: auto;
  fill: var(--white);
  margin-right: 0.35rem;
`;

export const PencilIcon = styled(FaPencilAlt)`
  width: 1.35rem;
  height: auto;
  fill: var(--white);
`;

export const TrashIcon = styled(FaTrash)`
  width: 1.35rem;
  height: auto;
  fill: var(--white);
`;
