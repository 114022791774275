import styled from 'styled-components';

export const Container = styled.div`
  height: 160px;
  width: 650px;
  margin: 20px auto;

  @media screen and (max-width: 420px) {
    width: 85%;
    height: auto;
  }
`;

export const CarrouselItem = styled.div`
  height: 160px;
  width: auto;

  @media screen and (max-width: 420px) {
    width: 85%;
    height: auto;
  }
`;

export const CarrouselImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;
