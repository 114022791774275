import React from 'react';
import { format, parseISO } from 'date-fns';

import * as S from './styles';

interface TutorialCardProps {
  id: string;
  tutorial_title: string;
  url_thumbnail: string;
  url_video: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  handleDelete: (id_tutorial: string) => void;
  handleUpdate?: () => void;
}

export function TutorialCard({
  id,
  tutorial_title,
  url_thumbnail,
  url_video,
  status,
  created_at,
  updated_at,
  handleDelete,
  handleUpdate,
}: TutorialCardProps) {
  return (
    <S.Container>
      <div>
        <S.Image
          src={`https://auth.redeinovadrogarias.com.br:8012/uploads/tutorials/${url_thumbnail}`}
          alt='thumbnail'
        />
      </div>
      <S.InfosContainer>
        <span>Título: {tutorial_title}</span>
        <span>
          Criado: {format(parseISO(created_at.toString()), 'dd/MM/yyyy')}
        </span>
        <span>
          Atualizado:{' '}
          {updated_at === null
            ? ''
            : format(parseISO(updated_at.toString()), 'dd/MM/yyyy')}
        </span>
      </S.InfosContainer>
      <S.InfosContainer>
        <span>
          Vídeo:{' '}
          <S.View href={url_video} target='_blank'>
            <S.EyeIcon /> visualizar
          </S.View>
        </span>
        <span>
          Image:{' '}
          <S.View
            href={`https://auth.redeinovadrogarias.com.br:8012/uploads/tutorials/${url_thumbnail}`}
            target='_blank'
          >
            <S.EyeIcon /> visualizar
          </S.View>
        </span>
        <span>Status: {status ? 'Visível' : 'Não visível'}</span>
      </S.InfosContainer>
      <S.InfosContainer>
        <S.Button disabled bgColor='var(--primary-gradient-rid)' onClick={handleUpdate}>
          <S.PencilIcon />
        </S.Button>
        <S.Button bgColor='var(--danger)' onClick={() => handleDelete(id)}>
          <S.TrashIcon />
        </S.Button>
      </S.InfosContainer>
    </S.Container>
  );
}
