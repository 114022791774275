import { useState, useEffect, useCallback } from 'react';
import { apiInvoice } from 'services/data';
import { useUrl } from 'hooks/url';
import { useToast } from 'hooks/toast';
import { Loading, Template } from 'components';
import { format, parseISO } from 'date-fns';
import * as S from './styles';

interface Response {
  id?: number;
  emissao: Date;
  pdf: string;
  xml: string;
  pdf_prefeitura: string;
}

const NotaFiscal = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Response[]>([]);
  const { urlCrm } = useUrl();
  const { addToast } = useToast();

  async function fetchData() {
    try {
      const apiInvoiceData = await apiInvoice.index();

      setData(apiInvoiceData.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha ao buscar Notas Fiscais',
        description: 'Falha ao buscar os dados das notas fiscais',
      });
    }
    setIsLoading(false);
  }

  const handleLog = useCallback(
    (id, emissao, tipo, pdf) => {
      async function fetchData() {
        try {
          await apiInvoice.store(emissao).then(async () => {
            if (tipo === 'pdf') {
              await fetch(pdf, {
                method: 'GET',
                headers: {
                  'X-API-KEY': process.env.REACT_APP_API_PLUGNOTAS_KEY!,
                },
              })
                .then((response) => response.blob())
                .then((response) => {
                  const mount_pdf = URL.createObjectURL(response);
                  window.open(mount_pdf, '_blank');

                  setTimeout(() => URL.revokeObjectURL(mount_pdf), 5000);
                });
            } else {
              const notaFiscalUrl = `${urlCrm}/NfseOpen/${tipo}/?id=`;
              window.open(`${notaFiscalUrl}${id}`, '_blank');
            }
          });
        } catch (err: any) {
          addToast({
            type: 'error',
            title: 'Falha ao realizar o Log',
            description: (err as Error).message,
          });
        }
      }
      fetchData();
    },
    [addToast, urlCrm]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.ContainerContent>
            {data.map((item) => (
              <S.ContainerNfs key={item.id}>
                <div className='description'>
                  <span>Data de emissão</span>
                  <b>
                    {format(parseISO(item.emissao.toString()), 'dd/MM/yyyy')}
                  </b>
                </div>
                <div className='buttons'>
                  <span>Clique nos botões para baixar o arquivo desejado</span>
                  <button
                    className='button__archive'
                    onClick={() =>
                      handleLog(item.id, item.emissao, 'pdf', item.pdf)
                    }
                  >
                    <S.iconPdf />
                  </button>
                  <button
                    className='button__archive'
                    onClick={() =>
                      handleLog(item.id, item.emissao, 'xml', item.xml)
                    }
                  >
                    XML
                  </button>
                </div>
              </S.ContainerNfs>
            ))}
          </S.ContainerContent>
        </Template>
      )}
    </>
  );
};

export default NotaFiscal;
