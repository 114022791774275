import styled, { css } from 'styled-components';

interface BgProps {
  bgColor: 'red';
}

interface TitleProps {
  title: 'red';
}

const titleVariations = {
  red: css`
    color: var(--white);
    font-size: 1rem;
  `,
};

const bgColorVariations = {
  red: css`
    background-color: var(--red-500);
    border: none;
    transition: all 280ms ease-in-out;
    &:hover {
      filter: brightness(0.85);
    }
  `,
};

export const Container = styled.button<BgProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  cursor: pointer;

  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${(props) => bgColorVariations[props.bgColor]};
`;

export const Title = styled.h1<TitleProps>`
  ${(props) => titleVariations[props.title]}
`;
