import { apiAgencia } from 'services/api';

class DepartmentsApi {
  getAll() {
    return apiAgencia.get('/departments');
  }

  getById(id: string) {
    return apiAgencia.get(`/departments/${id}`);
  }
}

export default new DepartmentsApi();
