import api from '../../api';

class MarketingData {
  index() {
    return api.get(`marketing/kitvarejo`);
  }
  store(download_type: string) {
    return api.post(`marketing/kitvarejo/log`, { download_type });
  }
}

export default new MarketingData();
