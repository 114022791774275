import styled from 'styled-components';

export const Container = styled.div`
  height: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const LogoImage = styled.img`
  height: 100px;
  width: 250px;

  @media screen and (max-width: 420px) {
    align-self: center;
  }
`;

export const WhatsappContainer = styled.div`
  height: 80px;
  width: 250px;
  background: rgba(13, 193, 82, 1);
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10px;
  margin-left: 10px;

  @media screen and (max-width: 420px) {
    display: none;
  }
`;

export const WhatsappImage = styled.img`
  height: 35px;
  width: 35px;
`;

export const WhatsappInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
`;

export const WhatsappMessage = styled.span`
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const WhatsappNumber = styled.span`
  color: #fff;
  font-size: 15px;
  font-weight: 700;
`;
