import api from '../../api';

class FileData {
  geral(pasta: string) {
    return api.get(`administrativo/arquivo/geral/${pasta}`);
  }
  especifico(idPasta: number) {
    return api.get(`administrativo/arquivo/especifico/${idPasta}`);
  }
  comercial(idPasta: number) {
    return api.get(`administrativo/arquivo/comercial/varejo/${idPasta}`);
  }
  comercialStore(download: string) {
    return api.post(`administrativo/arquivo/comercial/varejo/log`, { download });
  }
}

export default new FileData();
