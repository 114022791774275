import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 1268px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  color: var(--white);
  font-size: 32px;

  @media (max-width: 1268px) {
    text-align: center;
    font-size: 24px;
    margin-bottom: 1rem;
  }
`;

export const IconTrash = styled(FaTrash)`
  width: 2.5rem;
  width: 2.5rem;
  color: #ffffff;
`;
