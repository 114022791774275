import styled from "styled-components";

export const Music = styled.div`
  position: absolute;
  left: 50px;
  display: flex;
  align-items: center;
  width: 40%;
  z-index: 1;
  @media (max-width: 1100px) {
    width: 35%;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

export const MusicImage = styled.div`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  border: 2px solid black;
  background-size: cover; 
  background-position: center;
  margin: 0 10px 0 0;
`;

export const MusicText = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 12px 20px;
  width: 80%;
  height: 40px;
  background: #fff;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  span {
    font-weight: bold;
    width: 120px;
    @media (max-width: 1100px) {
      width: 130px;
    }
    @media (max-width: 950px) {
      width: 150px;
    }
  }
  @keyframes scroll-left {
    from { transform: translateX(100%); }
    to { transform: translateX(-100%); }
  }
  div {
    width: 85%;
    height: 50px;
    overflow: hidden;
    position: relative;
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      line-height: 50px;
      text-align: center;
      transform: translateX(100%);
      animation: scroll-left 10s linear infinite;
    }
  }
`;

export const Volume = styled.button`
  cursor: pointer;
  background-color: #000;
  border: 0;
  outline: 0;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: 300ms ease-in-out;
  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  :hover {
    transform: scale(1.2);
  }
`;

export const Play = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  margin: 0 10px;
  z-index: 1;
  transition: 300ms ease-in-out;
  svg {
    width: 70px;
    height: 70px;
  }
  :hover {
    transform: scale(1.2);
  }
`;
export const Pause = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  z-index: 1;
  transition: all 300ms ease-in-out;
  svg {
    width: 35px;
    height: 35px;
  }
  :hover {
    transform: scale(1.2);
  }
`;

export const Player = styled.button`
  position: absolute;
  right: 150px;
  display: flex;
  align-items: center;
  border: 0;
  a {
    cursor: pointer;
    background-color: #fff;
    margin: 0 15px;
    border: 0;
    outline: 0;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 1;
    transition: 300ms ease-in-out;
    svg {
      color: #000;
      width: 20px;
      height: 20px;
    }
    :hover {
      transform: scale(1.2);
    }
  }
  @media (max-width: 1100px) {
    right: 50px;
    a {
      margin: 0 7px;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

export const Container = styled.div`
  background: #efefef;
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  height: 110px;
  border-radius: 8px;
  margin-top: 10px;
  audio {
    display: none;
  }
  @keyframes slidein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  .containerAnimation {height: 100px; width: 100%;  overflow: hidden; position: absolute; display: block; opacity: 0.3;}
	.animation > div {border-radius: 50%; width: 80px; height: 80px; border: solid 1px #333; left: 50%; top: 50%; margin-left: -40px; margin-top: -40px; display: block; position: absolute;}
	.animation > div + div {width: 90px; height: 90px; margin-left: -45px; margin-top: -45px; animation: slidein 1s linear 23s infinite alternate;}
	.animation > div + div + div {width: 100px; height: 100px; margin-left: -50px; margin-top: -50px; animation: slidein 2s linear 22s infinite alternate;}
	.animation > div + div + div + div {width: 110px; height: 110px; margin-left: -55px; margin-top: -55px; animation: slidein 3s linear 21s infinite alternate;}
	.animation > div + div + div + div + div {width: 120px; height: 120px; margin-left: -60px; margin-top: -60px; animation: slidein 4s linear 20s infinite alternate;}
	.animation > div + div + div + div + div + div {width: 140px; height: 140px; margin-left: -70px; margin-top: -70px; animation: slidein 5s linear 19s infinite alternate;}
	.animation > div + div + div + div + div + div + div {width: 160px; height: 160px; margin-left: -80px; margin-top: -80px; animation: slidein 6s linear 18s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div {width: 180px; height: 180px; margin-left: -90px; margin-top: -90px; animation: slidein 7s linear 17s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div {width: 200px; height: 200px; margin-left: -100px; margin-top: -100px; animation: slidein 8s linear 16s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div {width: 240px; height: 240px; margin-left: -120px; margin-top: -120px; animation: slidein 9s linear 15s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div {width: 280px; height: 280px; margin-left: -140px; margin-top: -140px; animation: slidein 10s linear 14s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div {width: 320px; height: 320px; margin-left: -160px; margin-top: -160px; animation: slidein 11s linear 13s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div {width: 360px; height: 360px; margin-left: -180px; margin-top: -180px; animation: slidein 12s linear 12s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 400px; height: 400px; margin-left: -200px; margin-top: -200px; animation: slidein 13s linear 11s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 500px; height: 500px; margin-left: -250px; margin-top: -250px; animation: slidein 14s linear 10s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 600px; height: 600px; margin-left: -300px; margin-top: -300px; animation: slidein 15s linear 9s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 700px; height: 700px; margin-left: -350px; margin-top: -350px; animation: slidein 16s linear 8s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 900px; height: 900px; margin-left: -450px; margin-top: -450px; animation: slidein 17s linear 7s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 1200px; height: 1200px; margin-left: -600px; margin-top: -600px; animation: slidein 18s linear 6s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 1500px; height: 1500px; margin-left: -750px; margin-top: -750px; animation: slidein 19s linear 5s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 1800px; height: 1800px; margin-left: -900px; margin-top: -900px; animation: slidein 20s linear 4s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 2200px; height: 2200px; margin-left: -1100px; margin-top: -1100px; animation: slidein 21s linear 3s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 2600px; height: 2600px; margin-left: -1300px; margin-top: -1300px; animation: slidein 22s linear 2s infinite alternate;}
	.animation > div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div + div {width: 3000px; height: 3000px; margin-left: -1500px; margin-top: -1500px; animation: slidein 23s linear 1s infinite alternate;}
`;