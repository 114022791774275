import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { IToken } from 'interfaces/User';
import { useKeycloak } from '@react-keycloak/web';
import { Loading, SaveButton, Template } from 'components';
import { useToast } from 'hooks/toast';
import { apiJornalDeOfertas } from 'services/data';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

const quanti = [
  500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500,
  7000, 7500, 8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 12500,
  13000, 13500, 14000, 14500, 15000, 15500, 16000, 16500, 17000, 17500, 18000,
  18500, 19000, 19500, 20000, 20500, 21000, 21500, 22000, 22500, 23000, 23500,
  24000, 24500, 25000,
];
interface IForm {
  name: string;
  code: number;
  email: string;
  state: string;
  ip: string;
  newspaperType: string;
  newspaperQuant: number;
  newspaperTimeRservation: number;
}

const formInitialState = {
  name: '',
  code: 0,
  email: '',
  state: '',
  ip: '',
  newspaperType: 'IMPRESSO',
  newspaperQuant: 500,
  newspaperTimeRservation: 1,
};

export function ComercialJornalDeOfertasFazerReserva() {
  const { keycloak } = useKeycloak();
  const { addToast } = useToast();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [handleFindIpAddress, setHandleFindIpAddress] = useState('');
  const [isForm, setIsForm] = useState<IForm>(formInitialState);
  const user: IToken = jwtDecode(keycloak.token || '');

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);

  /**
   * CAPTURA INFORMAÇÃO DE IP DO USUÁRIO
   */
  useEffect(() => {
    axios
      .get('https://api.ipify.org?format=json')
      .then((response) => setHandleFindIpAddress(response.data.ip))
      .catch(() => setHandleFindIpAddress('Não encontrado'))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsForm({
      ...isForm,
      name: user.name,
      code: Number(user.codigo),
      email: user.email,
      state: user.estado,
      ip: handleFindIpAddress,
    });
    //eslint-disable-next-line
  }, [handleFindIpAddress]);

  useEffect(() => {
    if (isForm.newspaperType === 'IMPRESSO') {
      setIsForm({
        ...isForm,
        newspaperQuant: 500,
      });
    } else {
      setIsForm({
        ...isForm,
        newspaperQuant: 0,
      });
    }
    //eslint-disable-next-line
  }, [isForm.newspaperType]);

  /**
   * ALTERA O ESTADOS DO INPUTS
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setIsForm({ ...isForm, [name]: value });
  };

  /**
   * ENVIA O FORMULÁRIO
   */
  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (checkbox1 && checkbox2 && checkbox3 && checkbox4) {
      try {
        await apiJornalDeOfertas.store(isForm);

        addToast({
          type: 'success',
          title: 'Êxito!!! :)',
          description: `Sua reserva foi realizada com sucesso.`,
        });

        setTimeout(() => {
          history.push('/comercial/jornalDeOfertasMinhasReservas');
        }, 1000);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: `${error}`,
        });
      }
    } else {
      addToast({
        type: 'info',
        title: 'Atenção!',
        description: 'É necessário marcar todas as cláusulas para continuar.',
      });
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.TitleContainer>
            <S.Title>Realize aqui a reserva do jornal de ofertas</S.Title>
          </S.TitleContainer>
          <form onSubmit={handleSubmit}>
            <S.RadioGroupContainer>
              <label>Selecione o tipo de jornal:</label>
              <div>
                <S.InputRadioCustomize
                  type='radio'
                  name='newspaperType'
                  id='newspaperType'
                  value='IMPRESSO'
                  checked={isForm.newspaperType === 'IMPRESSO'}
                  onChange={handleChange}
                />
                <label htmlFor='Jornal Impresso'>
                  Jornal Impresso (Artes Digitais + VT + SPOT) - R$99,99 cada
                  500pcs.
                </label>
              </div>
              <div>
                <S.InputRadioCustomize
                  type='radio'
                  name='newspaperType'
                  id='newspaperType'
                  value='DIGITAL'
                  checked={isForm.newspaperType === 'DIGITAL'}
                  onChange={handleChange}
                />
                <label htmlFor='Jornal Digital'>
                  Jornal Digital (Artes Digitais + VT + SPOT) - R$0,00.
                </label>
              </div>
            </S.RadioGroupContainer>

            <S.SubContainer>
              <S.WrapperForm>
                <div>
                  <label htmlFor='Reservar para'>Reservar para:</label>
                  <br />
                  <select
                    name='newspaperTimeRservation'
                    id='newspaperTimeRservation'
                    value={isForm.newspaperTimeRservation}
                    onChange={handleChange}
                  >
                    <option value={1}>1 mês</option>
                    <option value={2}>2 meses</option>
                    <option value={3}>3 meses</option>
                    <option value={4}>4 meses</option>
                    <option value={5}>5 meses</option>
                    <option value={6}>6 meses</option>
                  </select>
                </div>
                <div>
                  <label htmlFor='Quantidade'>Quantidade:</label>
                  <br />
                  <select
                    name='newspaperQuant'
                    id='newspaperQuant'
                    disabled={isForm.newspaperType === 'DIGITAL' ? true : false}
                    onChange={handleChange}
                  >
                    {quanti.map((quant) => (
                      <option key={quant} value={quant}>
                        {quant} unidades
                      </option>
                    ))}
                  </select>
                </div>
              </S.WrapperForm>
            </S.SubContainer>

            <S.CheckboxGroupContainer>
              <h5>Cláusulas de reserva</h5>

              <S.CheckboxContainer>
                <input
                  type='checkbox'
                  name='checkBox_1'
                  id='checkBox_1'
                  onChange={() => setCheckbox1(!checkbox1)}
                  checked={checkbox1}
                />
                <label htmlFor='checkBox_1'>
                  Confirmo a compra da quantidade de jornal impresso acima
                  selecionado, estou ciente que o mesmo será cobrado no boleto
                  da Rede com vencimento no dia 15 do mês de validade do jornal,
                  sendo que o custo é de R$ 99,99 a cada 500 unidades. (COBRANÇA
                  SOMENTE SOBRE JORNAL IMPRESSO).
                </label>
              </S.CheckboxContainer>
              <S.CheckboxContainer>
                <input
                  type='checkbox'
                  name='checkBox_2'
                  id='checkBox_2'
                  onChange={() => setCheckbox2(!checkbox2)}
                  checked={checkbox2}
                />
                <label htmlFor='checkBox_2'>
                  Estou ciente que devo programar a minha farmácia para receber
                  o jornal e para este fim receberei da Rede a planilha "Onde
                  comprar" e devo antecipar as compras para ter o estoque do que
                  será ofertado. Esta planilha estará disponível a partir o dia
                  20 nesta plataforma.
                </label>
              </S.CheckboxContainer>
              <S.CheckboxContainer>
                <input
                  type='checkbox'
                  name='checkBox_3'
                  id='checkBox_3'
                  onChange={() => setCheckbox3(!checkbox3)}
                  checked={checkbox3}
                />
                <label htmlFor='checkBox_3'>
                  Estou ciente que a logística do Jornal de Ofertas é feita por
                  distribuidores parceiros (MG = Distrimed, ES = Laborsil e BA =
                  Orgafarma) e terei que passar um pedido nesta distribuidora
                  depois do dia 25 para receber o Jornal. Os canais oficiais que
                  podem receber os pedidos são: Portal de compras da Rede (em
                  campanha específica) ou 0800 da distribuidora desde que no ato
                  da compra seja sinalizado que o pedido deve ser vinculado a
                  entrega dos jornais.
                </label>
              </S.CheckboxContainer>
              <S.CheckboxContainer>
                <input
                  type='checkbox'
                  name='checkBox_4'
                  id='checkBox_4'
                  onChange={() => setCheckbox4(!checkbox4)}
                  checked={checkbox4}
                />
                <label htmlFor='checkBox_4'>
                  Declaro estar ciente que neste aceite existe validação de IP e
                  o login e senha desta plataforma são pessoais, de
                  responsabilidade da farmácia e estou de acordo com as
                  premissas acima.
                </label>
              </S.CheckboxContainer>
              <S.IpAddressContainer style={{ justifyContent: 'center' }}>
                <S.IpAddressText>Seu IP: {handleFindIpAddress}</S.IpAddressText>
              </S.IpAddressContainer>
            </S.CheckboxGroupContainer>

            <S.ButtonContainer style={{ justifyContent: 'center' }}>
              <SaveButton bgColor='red' title='red' iconBg='#fff' />
            </S.ButtonContainer>
          </form>
        </Template>
      )}
    </>
  );
}
