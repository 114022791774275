import styled from 'styled-components';

export const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  text-align: left;
  color: var(--grey);

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 15px 20px;
  }
`;
