import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 20px;
`;

export const Body = styled.main`
  background: var(--gray-800);
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Audio = styled.div`
  display: none;
`;
