import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
// import api from "services/api";
import { useToast } from 'hooks/toast';

import {
  Loading,
  Card,
  Template,
} from 'components';
import { apiFile } from 'services/data';
import { useUrl } from 'hooks/url';

type FilesData = {
  id: number;
  file_name: string;
  uploaded_on: string;
};

const OndeComprar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<FilesData[]>([]);
  const { addToast } = useToast();
  const { urlCrm } = useUrl();

  useEffect(() => {
    async function fetchData() {
      try {
        await apiFile.comercial(11).then((response) => setFiles(response.data));
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Erro ao buscar os arquivos',
          description: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [addToast]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          {files &&
            files.map((item) => {
              const extensao = item.file_name.split('.').pop() || 'png';
              return (
                <Card
                  key={item.id}
                  image={item.file_name}
                  extensao={extensao}
                  text={item.file_name
                    .split('_')
                    .join(' ')
                    .split('.')
                    .slice(0, -1)
                    .join('')}
                  legend={`${format(
                    parseISO(item.uploaded_on.toString()),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: ptBR,
                    }
                  )}`}
                  path={`${urlCrm}/arquivo/999/${item.file_name}`}
                  log={true}
                />
              );
            })}
        </Template>
      )}
    </>
  );
};

export default OndeComprar;
