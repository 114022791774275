import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Template } from 'components';
import { ButtonGigi } from 'styles';

const ComercialMenu = () => {
  const history = useHistory();

  const date = useMemo(() => {
    const nowDate = new Date();
    const nowDay = nowDate.getDate();

    if (nowDay <= 15) {
      return false;
    } else {
      return true;
    }
  }, []);

  return (
    <Template>
      <div className='button-content'>
        <ButtonGigi
          bgColor='red'
          onClick={() => history.push('jornalDeOfertasMinhasReservas')}
        >
          Minhas Reservas
        </ButtonGigi>
        <ButtonGigi
          bgColor={date ? 'disabled' : 'red'}
          onClick={() => history.push('jornalDeOfertasFazerReserva')}
          disabled={date}
        >
          Fazer Reservas
        </ButtonGigi>
      </div>
    </Template>
  );
};

export default ComercialMenu;
