import styled from 'styled-components';

export const Body = styled.main`
  background: var(--gray-800);
  min-height: 100vh;
  padding-bottom: 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    height: 4rem;
    border-radius: 1rem;
    background: var(--success);
    color: var(--white);
    text-decoration: none;
    transition: filter 350ms;

    svg {
      color: var(--white);
      margin-right: 1rem;
    }

    &:hover {
      filter: brightness(0.85);
    }

    @media screen and (max-width: 420px) {
      width: 90%;
      height: 4rem;
    }
  }

  @media (max-width: 710px) {
    flex-direction: column;
  }
`;

export const Player = styled.video`
  aspect-ratio: 16/9;
  border-radius: 1rem;
  margin: 1rem 0;

  @media screen and (max-width: 860px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 420px) {
    width: 90%;
    height: auto;
  }
`;
