import React, { useCallback } from 'react';
import { apiFile } from 'services/data';
import { useToast } from 'hooks/toast';
import pdf from 'assets/images/arquivos/pdf.svg';
import word from 'assets/images/arquivos/word.svg';
import xls from 'assets/images/arquivos/xls.svg';

import * as S from './styles';
import { Button } from 'styles';

type Props = {
  image: string;
  text: string;
  legend: string;
  path: string;
  extensao: string;
  log?: boolean;
};

const Card = ({ image, text, path, extensao, legend, log }: Props) => {
  const { addToast } = useToast();

  const handleClick = useCallback(() => {
    async function fetchData() {
      try {
        await apiFile.comercialStore(image).then(() => {
          window.open(path, '_blank');
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao realizar o Log',
          description: (err as Error).message,
        });
      }
    }
    if (log) {
      fetchData();
    } else {
      window.open(path, '_blank');
    }
  }, [path, log, addToast, image]);

  return (
    <S.Container>
      <S.Image
        src={
          extensao === 'pdf'
            ? pdf
            : extensao === 'docx'
            ? word
            : extensao === 'xlsx'
            ? xls
            : image
        }
        alt='Imagem de arquivos dos licenciados'
      />
      <S.Text>{text}</S.Text>
      <S.Legend>{legend}</S.Legend>
      <Button onClick={handleClick}>VER MAIS</Button>
    </S.Container>
  );
};

export default Card;
