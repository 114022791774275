import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'react-router-dom';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { Loading } from 'components';
import api, { apiAgencia } from 'services/api';

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { keycloak, initialized } = useKeycloak();

  const [urlApi, setUrlApi] = useState('');
  const [urlApiAgencia, setUrlApiAgencia] = useState('');

  useEffect(() => {
    const verify = async () => {
      let urlAtual =
        'https://auth.redeinovadrogarias.com.br:8015/auth/realms/licenciado';
      try {
        await axios({
          method: 'get',
          url: urlAtual,
          timeout: 1000,
        });
        setUrlApi('https://sistema.redeinovadrogarias.com.br:8011');
        setUrlApiAgencia('https://auth.redeinovadrogarias.com.br:8012');
      } catch (error) {
        setUrlApi('https://sistema1.redeinovadrogarias.com.br:8011');
        setUrlApiAgencia('https://auth1.redeinovadrogarias.com.br:8012');
      }
    };
    verify();
  }, []);

  if (!initialized || !urlApi || !urlApiAgencia) {
    return <Loading />;
  }

  api.interceptors.request.use(async (request) => {
    const kcToken = keycloak?.token ?? '';
    if (urlApi) {
      request.baseURL = urlApi;
    }

    if (kcToken !== '') {
      request.headers.authorization = `Bearer ${kcToken}`;
    }
    return request;
  });

  apiAgencia.interceptors.request.use(async (request) => {
    const kcToken = keycloak?.token ?? '';
    if (urlApiAgencia) {
      request.baseURL = urlApiAgencia;
    }

    if (kcToken !== '') {
      request.headers.authorization = `Bearer ${kcToken}`;
    }
    return request;
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
