import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { PrivateRoute } from './PrivateRoute';

import {
  Radio,
  Television,
  Locucao,
  Edicao,
  InitialLogin,
  InitialBemVindo,
  MarketingMenu,
  MarketingKitJornalOfertas,
  MarketingKitJornalOfertaPaginas,
  ComercialMenu,
  ComercialJornalOfertas,
  ComercialJornalDeOfertasFazerReserva,
  ComercialJornalDeOfertasMinhasReservas,
  ComercialOndeComprar,
  AnjoMenu,
  AnjoAtendimento,
  AnjoAtendimentoVer,
  AdministrativoMenu,
  AdministrativoArquivo,
  AdministrativoArquivosGeral,
  AdministrativoArquivosGeralComunicados,
  AdministrativoParaSuaFarmacia,
  AdministrativoSuaFarmaciaGeofusion,
  AdministrativoSuaFarmaciaCredenciamento,
  AdministrativoSuaFarmaciaImplantacao,
  AdministrativoFinanceiro,
  AdministrativoNotaFiscal,
  ParceirosComerciais,
  ParceirosComerciaisDetails,
  Treinamentos,
  AdminMenu,
  AdminTreinamentos,
} from '../pages';

import { Loading } from 'components';

const Routes = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path='/login' component={InitialLogin} />
      <PrivateRoute exact path='/' component={InitialBemVindo} />
      <PrivateRoute exact path='/marketing' component={MarketingMenu} />
      <PrivateRoute
        exact
        path='/marketing/kitjornalofertas'
        component={MarketingKitJornalOfertas}
      />
      <PrivateRoute
        exact
        path='/marketing/kitjornalofertas/paginas'
        component={MarketingKitJornalOfertaPaginas}
      />
      <PrivateRoute exact path='/comercial' component={ComercialMenu} />
      <PrivateRoute
        exact
        path='/comercial/jornalofertas'
        component={ComercialJornalOfertas}
      />
      <PrivateRoute
        exact
        path='/comercial/jornalDeOfertasFazerReserva'
        component={ComercialJornalDeOfertasFazerReserva}
      />
      <PrivateRoute
        exact
        path='/comercial/jornalDeOfertasMinhasReservas'
        component={ComercialJornalDeOfertasMinhasReservas}
      />
      <PrivateRoute
        exact
        path='/comercial/ondecomprar'
        component={ComercialOndeComprar}
      />
      <PrivateRoute exact path='/anjo' component={AnjoMenu} />
      <PrivateRoute
        exact
        path='/anjo/atendimentos'
        component={AnjoAtendimento}
      />
      <PrivateRoute
        exact
        path='/anjo/atendimentos/:id'
        component={AnjoAtendimentoVer}
      />
      <PrivateRoute
        exact
        path='/administrativo'
        component={AdministrativoMenu}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos'
        component={AdministrativoArquivo}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/geral'
        component={AdministrativoArquivosGeral}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/geral/comunicados'
        component={AdministrativoArquivosGeralComunicados}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/parasuafarmacia'
        component={AdministrativoParaSuaFarmacia}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/parasuafarmacia/geofusion'
        component={AdministrativoSuaFarmaciaGeofusion}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/parasuafarmacia/credenciamento'
        component={AdministrativoSuaFarmaciaCredenciamento}
      />
      <PrivateRoute
        exact
        path='/administrativo/arquivos/parasuafarmacia/implantacao'
        component={AdministrativoSuaFarmaciaImplantacao}
      />
      <PrivateRoute
        exact
        path='/administrativo/financeiro'
        component={AdministrativoFinanceiro}
      />
      <PrivateRoute
        exact
        path='/administrativo/notafiscal'
        component={AdministrativoNotaFiscal}
      />
      <PrivateRoute
        exact
        path='/administrativo/parceirosComerciais'
        component={ParceirosComerciais}
      />
      <PrivateRoute
        exact
        path='/administrativo/parceirosComerciais/:id'
        component={ParceirosComerciaisDetails}
      />
      <PrivateRoute exact path='/treinamentos' component={Treinamentos} />
      <PrivateRoute exact path='/admin' component={AdminMenu} />
      <PrivateRoute
        exact
        path='/admin/treinamentos'
        component={AdminTreinamentos}
      />
      <PrivateRoute exact path='/radio' component={Radio} />
      <PrivateRoute exact path='/tv' component={Television} />
      <PrivateRoute exact path='/locucao' component={Locucao} />
      <PrivateRoute exact path='/edicao' component={Edicao} />
    </Switch>
  );
};

export default Routes;
