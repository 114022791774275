import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from 'jwt-decode';
import { useToast } from 'hooks/toast';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';
import { apiMarketing } from 'services/data';

interface iToken {
  codigo: number;
}

type KitVarejoData = {
  vt: string;
  'jornal-ofertas': string;
  'jornal-online': string;
};

const KitJornalOfertas = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [kitVarejo, setKitVarejo] = useState<KitVarejoData>();
  const [isReservation, SetIsReservation] = useState(false);
  const { addToast } = useToast();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        await apiMarketing
          .index()
          .then((response) => setKitVarejo(response.data));
        SetIsReservation(true);
      } catch (err) {
        SetIsReservation(false);
        addToast({
          type: 'info',
          title: 'Nenhuma reserva foi localizada!',
          description: 'Entre em contato com setor Comercial',
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [addToast]);

  const handleLog = useCallback(
    (download_type) => {
      async function fetchData() {
        try {
          const token: iToken = jwt_decode(keycloak.token || '');

          await apiMarketing.store(download_type).then(() => {
            let codigo: number | string | undefined = token.codigo;
            let extensao = '';

            switch (download_type) {
              case 'spots':
                extensao = '.mp3';
                break;
              case 'vt':
                extensao = '/vt.mp4';
                codigo = kitVarejo?.vt;
                break;
              case 'artes-ind':
                extensao = '.zip';
                break;
              case 'jornal-online':
                extensao = `/jornal-online.zip`;
                codigo = kitVarejo?.['jornal-online'];
                break;
              default:
                break;
            }

            const kitVarejoUrl =
              'https://rid-growup.s3.amazonaws.com/kitvarejo';

            window.open(
              `${kitVarejoUrl}/${download_type}/${codigo}${extensao}`,
              '_blank'
            );
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Erro ao realizar o Log',
            description: (err as Error).message,
          });
        }
      }
      fetchData();
    },
    [kitVarejo, addToast, keycloak]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi
              onClick={() =>
                history.push('/marketing/kitjornalofertas/paginas')
              }
              bgColor={isReservation === false ? 'disabled' : 'red'}
              disabled={isReservation === false ? true : false}
            >
              ARTES
            </ButtonGigi>
            <ButtonGigi
              bgColor={isReservation === false ? 'disabled' : 'red'}
              disabled={isReservation === false ? true : false}
              onClick={() => handleLog('artes-ind')}
            >
              ARTES INDIVIDUAIS
            </ButtonGigi>
            <ButtonGigi
              bgColor={isReservation === false ? 'disabled' : 'red'}
              disabled={isReservation === false ? true : false}
              onClick={() => handleLog('spots')}
            >
              SPOT
            </ButtonGigi>
            <ButtonGigi
              bgColor={isReservation === false ? 'disabled' : 'red'}
              disabled={isReservation === false ? true : false}
              onClick={() => handleLog('vt')}
            >
              VT
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default KitJornalOfertas;
