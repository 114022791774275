import api from '../../api';
import { IAngel } from 'interfaces/Angel/Angel.interface';

class AngelData {
  index() {
    return api.get(`anjo/atendimento`);
  }
  show(id: number) {
    return api.get(`anjo/atendimento/${id}`);
  }
  store(body: IAngel) {
    return api.post(`anjo/atendimento`, body);
  }
}

export default new AngelData();
