import React, { useCallback, useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import jwtDecode from 'jwt-decode';
import ModalNotifications from '../ModalNotifications/ModalNotifications';
import { useToast } from 'hooks/toast';
import { FaShieldAlt, FaBell, FaSignOutAlt } from 'react-icons/fa';
import { ITokenJwt } from 'interfaces/TokenJWT';
import LogoInova from '../../assets/images/icons/logo_id_house_inova.svg';
import * as S from './styles';

interface ITemplateProps {
  children: React.ReactNode;
}

export function Template({ children }: ITemplateProps) {
  const { keycloak } = useKeycloak();
  const { addToast } = useToast();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const token: ITokenJwt = jwtDecode(keycloak.token || '');
  let admin = false;

  if (
    token.realm_access.roles.includes('admin') ||
    token.realm_access.roles.includes('employee-admin')
  ) {
    admin = true;
  }

  const location = useLocation();
  const caminho = location.pathname.split('/');
  caminho.splice(caminho.length - 1);
  const voltar = caminho.join('/');

  const handleShowModal = () => {
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  const handleSignout = useCallback(() => {
    addToast({
      type: 'info',
      title: 'aah :(',
      description: 'Volte sempre!',
    });
    keycloak.logout();
  }, [addToast, keycloak]);

  return (
    <S.Wrapper>
      <S.WrapperHeader>
        <S.Header>
          <S.NavigationControlls>
            {location.pathname !== '/' ? (
              <>
                <Link to='/'>HOME</Link> <span>| </span>
                <Link to={voltar}>VOLTAR</Link>
              </>
            ) : (
              <></>
            )}
          </S.NavigationControlls>
          <S.OrthersControlls>
            {admin && (
              <FaShieldAlt
                onClick={() => history.replace('/admin')}
                title='Área administrativa'
              />
            )}
            <FaBell onClick={handleShowModal} title='Notificações' />
            <ModalNotifications
              showModal={showModal}
              setShowModal={setShowModal}
            />
            <FaSignOutAlt onClick={handleSignout} title='Realizar logoff' />
          </S.OrthersControlls>
        </S.Header>
      </S.WrapperHeader>
      <S.WrapperContent>
        <S.Content>{children}</S.Content>
      </S.WrapperContent>
      <S.Footer>
        <img
          src={LogoInova}
          alt='Logo marca da ID.House e Rede Inova Drogarias'
        />
      </S.Footer>
    </S.Wrapper>
  );
}
