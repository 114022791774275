import React from 'react';
import { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

const Login = () => {
  const location = useLocation<{ [key: string]: unknown }>();
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  };

  const { keycloak } = useKeycloak();

  const login = useCallback(async () => {
    await keycloak?.login();
  }, [keycloak]);

  if (!!keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />;
  } else {
    login();
    return <></>;
  }
};

export default Login;
