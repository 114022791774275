import { useState, useEffect } from 'react';
import { apiParceirosComerciais } from 'services/data';
import { useParams } from 'react-router';
import { useToast } from 'hooks/toast';
import { Loading, Template } from 'components';
import { IParceirosComerciais } from '../../../../interfaces/ParceirosComerciais/ParceirosComerciais';
import * as S from './styles';

interface IParam {
  id: string;
}

const ParceirosComerciaisDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IParceirosComerciais>();
  const { addToast } = useToast();
  const { id } = useParams<IParam>();

  useEffect(() => {
    async function fetchData() {
      try {
        await apiParceirosComerciais
          .showOne(id)
          .then((response) => setData(response.data));
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar informações do parceiro',
          description: 'Falha ao buscar os dados do parceiro',
        });
      }
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <S.Content>
            <S.Row>
              <h4>Empresa:</h4>
              <span>{data?.empresa}</span>
            </S.Row>
            <S.Row>
              <h4>Responsável:</h4>
              <span>{data?.responsavel}</span>
            </S.Row>
            <S.Row>
              <h4>Ramo:</h4>
              <span>{data?.ramo}</span>
            </S.Row>
            <S.RowDouble>
              <div>
                <h4>Telefone:</h4>
                <span>{data?.contato}</span>
              </div>
              <div>
                <h4>E-mail:</h4>
                <span>{data?.email}</span>
              </div>
            </S.RowDouble>
            <S.Row>
              <h4>Condição:</h4>
              <span>{data?.condicao}</span>
            </S.Row>
          </S.Content>
        </Template>
      )}
    </>
  );
};

export default ParceirosComerciaisDetails;
