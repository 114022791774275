import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from 'hooks/toast';
import { ButtonGigi } from 'styles';
import { Loading, Template } from 'components';
import { apiMarketing } from 'services/data';

type KitVarejoData = {
  vt: string;
  'jornal-ofertas': string;
  'jornal-online': string;
};

const KitJornalOfertas = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [kitVarejo, setKitVarejo] = useState<KitVarejoData>();
  const { addToast } = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        await apiMarketing
          .index()
          .then((response) => setKitVarejo(response.data));
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar KIT',
          description: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
    fetchData();
  }, [addToast]);

  const handleLog = useCallback(
    (pg) => {
      async function fetchData() {
        try {
          console.log(kitVarejo, kitVarejo?.['jornal-ofertas']);
          const download_type = 'jornal-ofertas';
          await apiMarketing.store(`${download_type}-${pg}`).then(() => {
            const extensao = `/${pg}.jpg`;
            const kitVarejoUrl =
              'https://rid-growup.s3.amazonaws.com/kitvarejo';
            window.open(
              `${kitVarejoUrl}/${download_type}/${kitVarejo?.['jornal-ofertas']}${extensao}`,
              '_blank'
            );
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Erro ao realizar o Log',
            description: (err as Error).message,
          });
        }
      }
      fetchData();
    },
    [kitVarejo, addToast]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Template>
          <div className='button-content'>
            <ButtonGigi bgColor='red' onClick={() => handleLog('pagina1')}>
              PÁGINA 1
            </ButtonGigi>
            <ButtonGigi bgColor='red' onClick={() => handleLog('pagina2')}>
              PÁGINA 2
            </ButtonGigi>
            <ButtonGigi bgColor='red' onClick={() => handleLog('pagina3')}>
              PÁGINA 3
            </ButtonGigi>
            <ButtonGigi bgColor='red' onClick={() => handleLog('pagina4')}>
              PÁGINA 4
            </ButtonGigi>
          </div>
        </Template>
      )}
    </>
  );
};

export default KitJornalOfertas;
