import styled, { css } from 'styled-components';

interface InputProps {
  error?: boolean;
}

const Input = styled.input<InputProps>`
  height: 3.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--main);
  font-size: 1rem;
  padding: 0 1rem;
  ::placeholder {
    color: var(--black);
    font-weight: 500;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--black);
  }
  ${(props) =>
    props.error &&
    css`
      &:focus {
        background: var(--red-50);
        color: var(--white);
        border-color: var(--border-error);
      }
    `}
`;
export default Input;
